*{
    padding:0;
    margin:0;
    box-sizing: border-box;
}

.home-container{
    width:100%;
    height:100vh;
    /* height:100%; */
    display:flex;
}