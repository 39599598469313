.land-footer-container{
    /* padding: 16px 20px; */
    padding: 32px 56px;
    margin-top:50px;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: #1C1C1C;
    color: #FFFFFF;
}
.land-logo-footer{
    width:111px;
    color:#FFFFFF;
}
.land-logo-name-footer{
    width:100%;
}
.land-footer-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.land-link-list{
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-decoration: none;
    margin-top: 24px;
}

@media only screen and (min-width:768px){
    .land-logo-name-footer{
        width:104px;
    }
    .land-link-list{
        display:inline;
        font-size: 18px;
        line-height: 21px;
    }
    .land-footer-links{
        flex-direction: row;
        gap:20px;
    }
    
}
@media only screen and (min-width:1024px){
    .land-footer-container{
        width:100%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
       
        /* padding: 0px 20px; */
    }
    .land-logo-footer{
        width:104px;
    }
    .land-logo-name-footer{
        width:100%;
    }
    .land-link-list{
        margin: auto;
    }

}