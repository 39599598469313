.match-insight-wrapper{
    display:flex;
    justify-content: space-between;
    gap:15px;
}
.match-insight-content-left{
    width:73%;
    /* width:70%; */
}
.match-insight-content-right{
    width:25%;
    /* width:30%; */
}
.insights-top-d{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.insights-text-d{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #0A0A0A;
}
.insights-lang-block-d{
    display:flex;
    align-items: center;
    gap: 3px;
    background: #F6F3EF;
    border-radius: 4px;
}
.insights-lang-eng-d{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
    padding: 4px 10px;
    cursor: pointer;
}
.insights-lang-hindi-d{
    font-family: 'Noto Sans Devanagari';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 106.1%;
    color: #0A0A0A;
    cursor: pointer;
    padding: 6px 10px;
}
.insight-lang-bg-d{
    background: #0A0A0A;
    border-radius: 4px;
    color: #FFFFFF;
}