/* .guess-cs-container{
    margin-top:16px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
} */

.guess-cs-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}
.guess-cs-wrapper{
    background-color: #F6F3EF;
    border-radius: 8px;
    padding:8px 16px;
    margin-bottom: 15px;
}
.guess-bat-team-wrapper{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:16px 0px;
   
    /* margin-top:20px; */
}
.guess-bat-team{
    display:flex;
    gap:10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* color: #7A7A7A; */
}
.guess-bat-team button{
    border: 1px solid #A8A8A8;
    border-radius: 4px;
    padding: 8px 12px;
    color: #7A7A7A;
    width:70px;
    cursor:pointer;
}
.appr-hide{
    display:none;
}
.guess-bat-team .appr-active-team{
    border: 1px solid #3287D4;
    color: #3287D4;
}
.guess-inputs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:16px 0px;
}
.guess-adv-wrapper{
    display:flex;
    justify-content: space-between;
    align-items: center;
    /* transition: all 2s ease-in-out; */
}
.guess-inputs-adv{
    display:flex;
    flex-direction: column;
    font-size: 12px;
    padding:16px 0px;
    width:45%;
}
.guess-inputs-adv label{
    margin-bottom: 2px;
}
.guess-inputs select, .guess-inputs-adv input{
    padding: 8px 12px;
    border: 1px solid #A8A8A8;
    border-radius: 4px;
    width:32%;
    outline:none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    background-color: transparent;
}

.guess-inputs-adv input{
    width:100%;
}
.guess-input-options option{
    padding:10px 0px;
}



.appr-adv-input-container{
    border-top: 1px solid #E0E0E0;
    padding: 12px 0px;
}
.appr-adv-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #7A7A7A;
    cursor:pointer;
}


/* Desktop styles */
@media (min-width: 768px) { /* Adjust breakpoint as necessary */
    .guess-cs-container {
        flex-direction: row;
        justify-content: space-between; /* Ensure the cards are spaced out */
    }
    .guess-cs-wrapper {
        flex: 1; /* Makes each card take equal space */
        margin: 0 5px; /* Adds spacing between the cards */
        max-height: 50vh; /* 100% of the parent height minus 40vh */
        overflow-y: auto; /* Enables vertical scrolling */
        padding-bottom: 15px;
    }

    .guess-cs-wrapper::-webkit-scrollbar{
        width: 3px;
      }
    .guess-cs-wrapper::-webkit-scrollbar-thumb{
        background-color: #025A4E;
        border-radius: 2px;
      }
}