
.insight-container-d {
  display: flex;
  flex-direction: column;
  height: 65vh;
  overflow-y: auto; 
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  background-color: #F6F3EF; 
  padding: 20px;
  padding-bottom:50px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top:20px;
  /* margin-bottom: 20px;   */
}

.insight-container-d::-webkit-scrollbar{
  width: 3px;
}
.insight-container-d::-webkit-scrollbar-thumb{
  background-color: #025A4E;
  border-radius: 2px;
}

.chart-box{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.insight-chart-container-d{
  border: 2px solid #EBEBEB;
  border-radius: 12px;
  /* margin:10px 0px 20px 0px; */
  padding:10px 5px;
  /* width:33% */
  flex: 1;
}
.insight-chart-container-d:first-child {
  flex: 2; /* Double the size of the first container */
}
.chartTitle{
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.CheatSheetTitle{
  font-family: inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-grow: 1;
}

/*
.chart-box::-webkit-scrollbar{
  width: 3px;
  cursor: pointer;
}
.chart-box::-webkit-scrollbar-thumb{
  background-color: #025A4E;
  border-radius: 2px;
}
 */ 
  
.chart-panel h6 {
    margin-bottom: 20px; /* Space below the title */
    color: #333; /* Dark color for better readability */
    font-size: 1.25rem; /* Larger font size for headings */
    font-weight: bold; /* Bold font weight for headings */
}


.chart-panel .MuiTypography-root {
    color: #666; /* Softer color for messages */
    text-align: center; /* Center-align the text */
    margin-top: 50px; /* Push the message down to center vertically */
}
  
.data-panel {
  flex: 1;  /* Ensures it fills the space in flex container */
  padding: 20px;
  height: 60%;
  background-color: #FFFFFF;
  overflow-y: auto; /* Ensures scrollbar appears if content overflows */
}

.chart-panel {
  flex: 1;  /* Allows each panel to grow and fill the container */
  overflow-x: hidden;
  overflow-y: hidden; /* Allows vertical scroll if content overflows */
}
  
.data-panel h6, .data-panel h2 {
  margin-top: 10px;
  margin-bottom: 15px;
  color: #333333;
  font-size: 1.25rem;
  font-weight: bold;
}

.data-panel div {
  /* font-family: 'Public Sans', sans-serif; */
  font-size: 14px;
  line-height: 1.6;
  color: #666666;
  text-align: justify;
}
  
li {
    /* font-family: 'Public Sans', sans-serif;  */
    font-size: 14px; /* Appropriate font size for list items */
    line-height: 1.8; /* Slightly more line height for readability */
    color: #333; /* Darker color for better readability */
    margin-bottom: 0.5em; /* Space below each list item */
    list-style-type: disc; /* Style of the bullet points */
    list-style-position: inside; /* Position of bullet inside the content flow */
    padding-left: 1em; /* Padding to the left for indentation */
}

table {
  width: 100%; /* Full-width tables */
  border-collapse: collapse; /* No double borders */
  margin-top: 10px; /* Space above the table */
  background-color: #f6f3ef; /* Slight background color for the table */
}

th,
td {
  border: 1px solid #e1e1e1; /* Light border for readability */
  padding: 8px; /* Padding in table cells */
  text-align: left; /* Align text to the left in cells */
}

th {
  background-color: #f1f3f4; /* Different background for header cells */
  color: #202124; /* Darker text color for contrast */
}

strong {
  font-weight: bold; 
  /* color: white;  */
  /* background-color: #025A4E;  */
  padding: 2px 5px; 
  border-radius: 3px; 
}

.buildTeamButton{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
    background-color: #00876c;
    box-shadow: 0px 0px 48px rgba(2, 133, 115, 0.43);
    color: white;
    text-transform: none;
    border: none;
    padding: 14px 24px;
    cursor: pointer;
    /* height: 50px; */
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
/* 
.buildTeamButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007256;
  color: white; 
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
} */

.buildTeamButton:hover {
  background-color: #007256; /* Slightly darker green on hover */
  box-shadow: 0 0 8px 3px rgba(0, 135, 108, 0.5);  
}

.buildTeamButton:focus {
  box-shadow: 0 0 12px 4px #00876c;   
}



.build-team-button-container {
  position: fixed;      /* Stay in place regardless of scrolling */
  bottom: 20px;         /* 20px from the bottom of the viewport */
  left: 50%;            /* Center horizontally relative to the viewport */
  transform: translateX(-50%); /* Offset the element to the left by half its width */
  /* z-index: 1000;         */
  display: flex !important;
  justify-content: center;
  gap: 20px; 
}


.custom-tooltip {
  background-color: #fff; /* White background for clarity */
  border: 1px solid #ddd; /* Subtle border */
  padding: 10px 15px; /* Padding inside the tooltip */
  border-radius: 5px; /* Rounded corners for a smoother look */
  box-shadow: 0 3px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
  font-family: 'Public Sans', sans-serif; /* Consistent font with the rest of the UI */
  font-size: 0.9rem; /* Slightly smaller font size for tooltips */
  color: #333; /* Darker text for readability */
  text-align: left; /* Align text to the left */
  line-height: 1.4; /* Line height for better readability */
}

.custom-tooltip .label {
  font-weight: bold; /* Bold for the match label */
  color: #025A4E; /* Use a distinct color to highlight match name */
  margin-bottom: 5px; /* Space below the match name */
  
}

.custom-tooltip .intro, .custom-tooltip .desc {
  margin-bottom: 2px; /* Small space between lines for compact display */
}

.custom-tooltip .desc {
  color: #555; /* Slightly softer color for descriptions */
  font-style: italic; /* Italicize descriptions for style */
}
/*
.insight-box, .chart-box {
  background-color: #F6F3EF; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  /* max-height: 80vh;    */
  /* overflow-y: auto;  */
  /* padding-bottom:70px;   */
/*} */

.insightWrapper {
  /* max-height: 90%;    */
  /* overflow-y: hidden;  */
  /* font-family: 'PlusJakartaDisplay', sans-serif; */
  font-size: 1rem
}

.insightText p, .insightText ul, .insightText ol, .insightText h2, .insightText h3, .insightText br {
  line-height: 1.6; /* Adjust line spacing */
  margin-bottom: 8px;
  font-size: 14px; 
  text-align: justify;
  
}

.insightText  {
  /* max-height: 90%;
  height: 80% */
  font-family: inter;
  line-height: 1.6;
  font-size: 14px;
}

@font-face {
  font-family: 'PlusJakartaDisplay';
  src: url('../../../public/fonts/PlusJakartaDisplay-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaDisplay';
  src: url('../../../public/fonts/PlusJakartaDisplay-Medium.otf') format('opentype');
  font-weight: 500; /* Medium is often 500 weight */
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaDisplay';
  src: url('../../../public/fonts/PlusJakartaDisplay-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

.spinner {
  display: block;
  margin: 40px auto;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (min-width: 1202px){
  .chartTitle{
    font-size: 15px;
    line-height: 19px;
  }
}
@media only screen and (min-height: 600px){
  .insight-container-d {
    height: 73vh;
  }
}


.english-text {
  font-family: 'Inter', sans-serif;
}

.hindi-text {
  font-family: 'Noto Sans Devanagari', sans-serif;
}


.buildTeamButton img {
  width: 30px; /* Adjust size as needed */
  height: auto;
 
  margin-left: 10px;
}