.subscription-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    letter-spacing: 0.01em;
  }
  
  .subscription-modal {
    background: #f6f3EF;
    width: 50%;
    max-height: 90vh;
    overflow-y: auto;
    padding: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: #F6F3EF;
    font-weight: bold;
    border-radius: 15px;
  }
  
  .subscription-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    font-weight: bold;
  }

  .subscription-modal-header p{
    font-size: 20px;
    font-weight: 500;
  }
  
  .trial-icon {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    display: block;
  } 
  header p{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #0A0A0A;
  }
  .subscription-modal-content {
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.00938em;
    color: rgb(141, 141, 141);
  }
  .subscription-modal-content p{
    width:80%;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #8D8D8D;
    text-align: center;
    margin:0 auto;
  }
  
  .subscription-cards-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    margin-top: 24px;
    justify-content: center;
  }
  
  .subscription-card {
    width:50%;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 32px 24px;
  }
  
  .subscription-plan-type {
    text-align: left;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: #00867C;
    margin-bottom: 10px;
  }
  .subscription-plan-price{
    margin-bottom: 24px;
  }
  .subscription-modal-btn{
    padding: 12px 24px;
    font-family: 'Inter';
    font-style: normal;
    background: #00876C;
    border-radius: 1000px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #F6F3EF;
    border:none;
    width: 100%;
    cursor: pointer;
  }
  
  .subscription-modal-btn:hover {
    background-color: #00695c;
  }

  .subscription-whats-included  {
    padding-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
.subtitle2{
    font-size: 12px;
    line-height: 17px;
    color: #000000;
}

.subscription-plan-features {
  margin-top: 10px;
  list-style-type: circle;
}

.subscription-plan-features li {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #000000;
  list-style-position: inside;
  text-indent: -1.3em;
  padding-left: 25px;
  list-style: none;  
 
}
.subscription-plan-features li::before {
  content: '•'; 
  margin-right: 5px;
  color: #00867C; 
  font-size: 24px; 
  vertical-align: middle;
  line-height: 20px; 
}

.subscription-modal-text {
  margin-bottom: 16px;
  text-align: left; 
  color: #8d8d8d;
  padding-bottom: 20px;
}


.subscription-modal-title {
  text-align: center;
  background-color: #F6F3EF;
  font-weight: bold;
}

.subscription-price-amount {
  font-weight: bold;
  font-size: 30px;
  color: #000;
}

.subscription-plan-duration {
  font-size: 13px;
  color: #666;
}


.subscription-divider {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 20px;
}

  
  @media only screen and (max-width: 1024px) {
    .subscription-modal {
      width: 90%;
      padding-bottom:40px;
    }
  
    .subscription-cards-container {
      flex-direction: column;
    }
  
    .subscription-card {
      width: 100%;
    }
  }
 

