.recommend-container-m{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    color: #0A0A0A;
    padding:16px;
    min-height: 100vh;
    background-color: #EDE7DE;
    width:100%;
    padding-bottom: 50px;
}

.approachTeamHeading-m{
    /* Dream Team Recommendation */
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
}

.approachTeamSubHeading-m{
    /* Choose and save your teams */
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.tabPanelContainer-m{
    margin-top: 10px;
    width: 100%;
    display:flex;
    flex-direction: column;
    gap:10px;
}
.playerSerialAndCard-m{
    display: flex;
    align-items: center;
    gap:10px;   
    width:100%;
}

.playerSerialNumber-m{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #7A7A7A;
    width:5%;
}
.player-card-m{
    background-color: #F6F3EF;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1); 
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 16px 16px;
    width: 93%;
    border-radius: 8px; 
    
}

.cardContent-m{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:90%;
}

.playerDetailContainer-m{
    display: flex;
    flex-direction: row;
}   

.eachPlayerList-wrapper-m{
    display:flex;
    flex-direction: column;
    /* padding-right: 40px; */
}
.eachPlayerList-m{
    display:flex;
    align-items: center;
    gap:5px;
}

.teamName-m{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    font-size:8px;
    border-radius: 4px;
    font-weight: 700;
    line-height: 10px;
}

.teamStyleBlack {
    background-color: black;
    color: white;        
}
.teamStyleWhite {
    background-color: white;
    color: black;
}
.playerName-m {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
    white-space: nowrap; /* Prevent text from wrapping onto the next line */
    overflow: hidden; /* Hide text that goes beyond the element's bounds */
    text-overflow: ellipsis; /* Use an ellipsis to indicate clipped text */
}
.team-recom-player-icon{
    width:14px;
    height:14px;
}
.playerType-m {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;     
    white-space: nowrap; /* Prevent text from wrapping onto the next line */
    overflow: hidden; /* Hide text that goes beyond the element's bounds */
    text-overflow: ellipsis; /* Use an ellipsis to indicate clipped text */
}
.player-separator-m {
    display: block;
    min-height: 1px;  
    background-color: #d3d3d3; 
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
}


.captainContainer-m{    
    display: flex;
    align-items: center;
    gap:3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #A8A8A8;   
}
.role-circle-m {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: 1px solid #A8A8A8;
    border-radius: 50%; /* makes the div perfectly circular */
    cursor: pointer; /* indicates the element is clickable */
    user-select: none; /* prevents text selection */
}

.role-circle-m.selected {
    border-color: #00876c; /* change border color when selected */
    color: #00876c; /* change text color when selected */
    background-color: #00876c;
    color: #fff;
}
.team-recom-bottom-wrapper{
    background-color: #F6F3EF;
    padding:16px;
    width:100%;
    margin-top:15px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}
.team-recom-team-names{
    display:flex;
    align-items: center;
    gap:24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;
}
.team-recom-bottom-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
    margin-top: 10px;
    text-align: justify;
}
.team-recom-bottom-btns{
    display:flex;
    justify-content: center;
    gap: 16px;
    margin-top: 25px;
}
.team-recom-bottom-btn{
    padding: 10px 18px;
    /* background-color: #A8A8A8; */
    border-radius: 1000px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    border:none;
    outline: none;
    cursor:pointer;
}
.team-recom-gen-btn{
    background: #1C1C1C;
    box-shadow: 0px 0px 48px rgba(10, 10, 10, 0.32);
}
.team-recom-save-btn{
    background: #00876C;
    box-shadow: 0px 0px 48px rgba(2, 133, 115, 0.43);
}
.teamNametxt{
    padding: 2px 4px;
    font-size:8px;
    border-radius: 4px;
    font-weight: 700;
    line-height: 10px;
}


.playerCardsContainer{
    padding-top: 20px;
}

.blackButton {
    display: flex; /* Ensures flexbox layout */
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    border-radius: 40px;
    /* background-color: #f6f3ef; */
    background-color: #000;
    color: white;
    text-transform: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    font-size: 16px;
}



.blackButton:hover, .blackButton:focus {
    box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5);
}
