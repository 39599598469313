
.register-d-container{
    display: flex;
    width:100%;
    height:100vh;
    /* min-height:100vh */
    /* align-items: stretch; */
}
.register-d-left-wrapper {
    /* background-image: url('../../../images/desktop/cricket-team.svg'); */
    background-image: url(../../../public/images/desktop/cricket-team.svg);
    background-position: bottom center; /* Ensures the image is aligned to the bottom */
    background-size: contain; /* Ensures the image is not stretched */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-color: #96CBC5; /* Sets the background color */
    width: 50%;
    height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    font-family: 'Plus Jakarta Display';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 53px;
    color: #FFFFFF;
}

.register-d-left-wrapper p{
    width:60%;
}

.register-d-right-wrapper{
    width:50%;
    height:100%;
    padding:32px;
    padding-right: 200px;
}
.reg-d-logo{
    width:150px;
    height:40px;
}
.register-login-tabs-wrapper{
    padding-top:30px;
    padding-bottom: 10px;
    /* background-color: aqua; */
    border-bottom: 1px solid #A8A8A8;
    /* display: flex; */
    /* gap:5px; */
}
.register-login-tabs{
    padding: 10px 24px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}

.register-d-right-wrapper .active-tab{
    border-bottom: 2px solid #00876C;
}

/* Responsive adjustments for font size */

@media screen and (max-width: 1600px) {
    .register-d-left-wrapper {
        font-size: 35px;
        line-height: 45px;
    }
}

@media screen and (max-width: 1200px) {
    .register-d-left-wrapper {
        font-size: 30px;
        line-height: 45px;
    }
}

@media screen and (max-width: 900px) {
    .register-d-left-wrapper {
        font-size: 32px;
        line-height: 37px;
    }
}

@media screen and (max-width: 600px) {
    .register-d-left-wrapper {
        font-size: 24px;
        line-height: 28px;
    }
}