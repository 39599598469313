.MyTeamsContainer{
    display: flex;
    flex-direction: row;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    padding:24px;
    width:100%;
}

.MyTeamsFilterContainer{
    display: flex;
    align-items: center;    
}

.MyTeamsFilterContainer span{
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0A0A0A;
}

.MyTeamsSubtitle{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #585858;
    margin-left: 40px; 
    margin-right: 10px;
}

.MyTeamsFilterButtons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #00876c;
    border-radius: 5px;
}
.MyTeamsButton{
    border:none;
    padding: 10px 20px;
    margin: 0;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding:6px 15px;
}

.MyTeamsButton:first-of-type{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid #00876c;
}
.MyTeamsButton:last-of-type{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 1px solid #00876c;
}
.MyTeamsListAndPlayerListContainer{
    display: flex;
    gap:10px;
    margin-top: 24px;
}

.MyTeamsList-left{
    display:flex;
    width:55%;
}
.MyTeamsList{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #E0E0E0;
    padding:16px;
    height:60vh;
    background-color: #F6f3ef;
    width:40%;
}

.MyTeamCards{
    overflow-y: auto;
    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    gap:8px;
}

.MyTeamCards::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
  }
.MyTeamCards::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
  }

.teamItem{
    padding:5px 8px;
    /* background: rgba(158, 211, 204, 0.21); */
    border-radius: 4px;
    transition: background-color 0.3s;    
    cursor: pointer;   
}

.teamItem span{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}

.teamItem p{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;
}
.myteams-no-teams-text{
    font-size: 14px;
    line-height: 15px;
    overflow: hidden;
}

.MyTeamsDetails{
    display: flex;
    flex-direction: column;
    padding:16px;
    height: 60vh;
    background-color: #F6f3ef;
    font-family: 'inter', sans-serif;
    width:60%;
}
.MyTeam-name{
    width:100%;
    display:block;
    background-color: #A8A8A8; 
    padding:8px 0px;
    color: #FFFFFF;
    text-align: center; 
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;  
    margin-bottom: 16px;
    text-transform: uppercase; 
}

.MyTeamsPlayerListContainer{
    display: flex;
    flex-direction: column; 
    height:90%;
    overflow-y: auto;
}

.MyTeamsPlayerListContainer::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
  }
.MyTeamsPlayerListContainer::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
  }

.MyTeamsPlayerCard{
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #F6f3ef;
    border: 1px solid #F6f3ef;
    
}

.MyTeamsplayerDetailContainer{
    display: flex;
    align-items: center;
    width:100%;
    padding: 8px 0px;
    font-size: 14px;
}
.myteams-right-details{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:95%;
}
.myteam-serial{
    width:10%;
    font-size: 12px;
}
.myteam-details{
    display:flex;
    flex-direction: column;
    /* gap:5px; */
}
.myteams-playerdetails{
    display: flex;
    gap:5px;
}
.captainBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: 1px solid #A8A8A8;
    border-radius: 50%; 
    user-select: none; 
    border-color: #00876c; 
    color: #00876c; 
    margin-left: 20px;
    font-size: 12px;
}

.teamItem.selectedTeam {
    background-color: #e3ecea !important; /* Ensure override with `!important` if other styles conflict */
}

.MyTeamsFilterAndTeamListContainer{
    display: flex;
    flex-direction: column;
    width:100%;
}



.myteam-playerName{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.MyTeamsDetailsContainerWrapper{
    /* height: 75vh; */
    /* overflow: auto; */
    height:95%;
} 

.MyTeamsInsightChartContainer {
    width:45%;
    display: flex;
    flex-direction: column;
    background-color: #F6f3ef;
    /* padding:16px;  */
    height: 60vh;   
}
.MyTeamsInsight-wrapper{
    width:100%;
    overflow-y: auto; 
    overflow-x: hidden;
    height:95%;
    padding: 0px 16px 20px 16px;
}
.MyTeamsInsight-wrapper::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
  }
.MyTeamsInsight-wrapper::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
  }

.MyTeamsInsightChartContainer .MyTeam-name{
    margin:16px;
    width:92%;
} 

.MyTeamsInsightChartContainer p{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    text-align: justify;
}

.MyTeamsChartContainer{
    display: flex;
    font-size: 10px;
    margin-top: 30px;
}

@media only screen and (min-height:600px){
    .MyTeamsList{  
        height:70vh;
    }
    .MyTeamsDetails{
        height:70vh;
    }
    .MyTeamsInsightChartContainer {
        height: 70vh;   
    }
   
}
@media only screen and (min-width:1300px){
    .MyTeamsChartContainer{
        font-size: 12px;
    }
}