@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.how-to-use-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500; 
    font-family: inter;
    animation: fadeIn 0.3s; 
}

.how-to-use-modal-content {
    width: 70%;
    height: 90vh;
    background-color: #F6F3EF;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    z-index: 1501; 
}

.how-to-use-modal-header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    /* margin: 20px 40px;  */
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    position: relative; /* Added to support the grey line positioning */
}

.header-divider {
    position: absolute;
    left: 40px;  
    
    
    height: 1px;
    background-color: #ccc; /* Light grey color for the line */
    width: calc(100% - 120px); /* Adjusted width to fill space */
}

.modal-close-icon {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    
}


.how-to-use-modal-heading {
    width: 800px;
    height: 22px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0A0A0A;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}


.image-placeholder {
    /* width: 100%;   */
    max-width: 50%;
    display: flex; /* Use Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: 20px auto; /* Maintain horizontal centering with auto and vertical spacing */
    overflow: hidden; /* Prevent overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    /* background-color: #e0e0e0;   */
}

.image-placeholder img {
    width: auto;  /* Let the width be automatic */
    max-width: 100%;  /* Do not exceed the container's width */
    height: auto;  /* Maintain aspect ratio */
}




.how-to-use-modal-body {
    padding: 20px;
    overflow-y: auto; /* Confirm this is behaving as expected */
    margin-top: 10px;
    background-color: #F6F3EF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.7;
    height: calc(90vh - 100px); /* Check this calculation does not cut content */
}
.how-to-use-modal-body::-webkit-scrollbar{
    width: 3px;
  }
  .how-to-use-modal-body::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
  }

.how-to-use-modal-content-text {
    width: 70%;
    height: 90vh;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    overflow: hidden; /* This might help if content is overflowing */
}


/* Enhancing the headings within the modal body for clear section division */
.how-to-use-modal-body h5 {
    font-weight: 600;  
    margin-top: 20px;  
    margin-bottom: 10px; 
    color: #0A0A0A;  
    font-size: 16px;  
}

/* Styling for paragraphs to maintain consistency and readability */
.how-to-use-modal-body p {
    margin-bottom: 15px; 
}

/* Additional styling for strong tags to emphasize key terms */
.how-to-use-modal-body strong {
    font-weight: 500;  
    color: #222222;  
}

/* Responsive Design */
@media (max-width: 768px) {
    .how-to-use-modal-content {
        width: 100%; /* Full width on mobile */
    }

    /* .how-to-use-modal-header,
    .header-divider {
        left: 20px;
        right: 20px;
    } */
}
/* Animations */
@keyframes slideUp {
    from { background-color: transparent; }
    to { background-color: rgba(0, 0, 0, 0.4); }
}

@keyframes slideUpContent {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
}
