.landing-page{
    background-color: #EDE7DE;;
    /*background-color: #535353; */
    /* padding-bottom: 50px; */
    width:100%;
}

@media only screen and (min-width:768px){
    .landing-page{
        /* padding: 40px  25px; */
    }
}

@media only screen and (min-width:1024px){
    .landing-page{
        /* padding: 40px 25px 20px 25px; */
    }
}