.myteams-players-container{
    box-sizing: border-box;
    background-color: #EDE7DE;
    width:100%;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    color: #0A0A0A;
    padding-bottom: 20px;
}
.myteams-wrapper{
    background-color: #F6F3EF;
    border-radius: 8px;
    margin:16px 16px;
    padding:16px;
}
.teams-selected-names{
    background-color: #A8A8A8;
    border-radius: 3px;
    padding: 8px 0px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    display:flex;
    gap:5px;
    justify-content: center;
    width:100%;
}
.myteams-players-list-section{
    margin: 10px 16px;
    background-color: #F6F3EF;
    border-radius: 8px;
    padding: 8px 0px;
    display:flex;
    flex-direction: column;
    gap:20px;
}

.myteams-playerlist-wrapper{
    display:flex;
    justify-content: space-between;
    gap:20px;
}
.MyTeamsplayerDetailContainer-m{
    width:5%;
}
.myteams-players-details{
    width:95%;
}
.myteams-players-serial{
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #7A7A7A;
}

.myteams-players-btn-wrapper{
    /* display:fixed;
    bottom:20px; */
    text-align: center;
    padding:20px 0px;
}
.myteams-players-btn-wrapper button{
    padding: 12px 20px;
    background-color: #00876C;
    border-radius: 1000px;
    box-shadow: 0px 0px 48px rgba(2, 133, 115, 0.43);
    color:#FFFFFF;
    border:none;
    width:40%;
}
.role-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 1.5px solid  #00876c;
    color: #00876c;
    border-radius: 50%; /* makes the div perfectly circular */
    cursor: pointer; /* indicates the element is clickable */
    user-select: none; /* prevents text selection */
    font-size: 10px;
    font-weight: 600;
}

.myteams-insight-wrapper p{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0A0A0A;
    text-align: justify;
}

.myteams-chartContainer{
    margin-top: 20px;
    display:flex;
    flex-direction: column;
    gap:20px;
    font-size: 11px;
}

.loading-overlay{
    width:100%;
    min-height: 100vh;
    position: 'fixed';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: 'rgba(0, 0, 0, 0.5)';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    z-index: 1000
}