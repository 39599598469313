/* Base styles for mobile */
.player-card-container {
    padding: 25px 16px;
    background: #F6F3EF;
    /* min-height: 100vh; */
    overflow-y: auto;
    margin-top: 30px;
    border-radius: 16px 16px 0 0;
}

.player-card-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #E0E0E0;
}

.player-card-top-section span, .player-card-name-section span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0A0A0A;
}

.player-card-top-section img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.player-card-name-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 16px 0;
}

.player-card-name-section img {
    width: 48px;
    height: 48px;
}



/* Media query for desktop screens */
@media (min-width: 768px) {
    .player-card-container {
        /* padding: 50px;  */
        max-width: 80vw; /* Limiting the max width for better desktop viewing */
        margin: 0px auto; /* Centering in the viewport horizontally */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding some shadow for depth */
        height: 100vh;
        border-radius: 0px;
        overflow-y: hidden;
    }

    .player-card-top-section span, .player-card-name-section span {
        font-size: 20px; /* Larger font size for desktop */
        line-height: 24px; /* Adjust line height according to the font size */
    }

    .player-card-top-section img, .player-card-name-section img {
        width: 24px; /* Larger icons for desktop */
        height: 24px;
    }

    .player-card-name-section {
        flex-direction: row; /* Row layout for desktop */
        align-items: center; /* Align items vertically in the center */
        gap: 10px; /* Larger gap for desktop */
    }

    .player-performance-section {
        height: 60vh; /* Larger fixed height for desktop */
    }
	
	.player-performance-section {
    overflow-y: auto;
    height: 80vh;
}

	.player-performance-section::-webkit-scrollbar {
		width: 3px;
		cursor: pointer;
	}

	.player-performance-section::-webkit-scrollbar-thumb {
		background-color: #025A4E;
		border-radius: 2px;
	}
    
}
