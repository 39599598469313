/* src/pages/NotFound/NotFoundPage.css */

.not-found {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #EDE7DE;
    flex-direction: column;
    overflow-x: hidden;
  }
  
  .not-found-content {
    width: 70%;
    background-color: #F6F3EF;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin-top: 10px;
    border-radius: 25px;
    line-height: 2;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .not-found h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .not-found p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 2;
  }
  
  .not-found a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #00876C;
    padding: 12px 24px;
    border: 2px solid #00876C;
    border-radius: 1000px;
    cursor: pointer;
    background: none;
    text-decoration: none;
  }
  
  .not-found a:hover {
    text-decoration: underline;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 600px) {
    .not-found-content {
      width: 90%;
    }
  }
  