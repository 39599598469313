.teamsavedmodal_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.teamsavedmodal_modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 80%;
    text-align: center;
}

.teamsavedmodal_modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.teamsavedmodal_modal-body {
    margin-top: 20px;
}

.teamsavedmodal_modal-body img {
    margin-bottom: 10px;
}

.teamsavedmodal_modal-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.teamsavedmodal_modal-text {
    font-size: 12px;
}

@media only screen and (min-width:1024px){
    .teamsavedmodal_modal-content {
        width: 400px;
    }
}