

.upcom-container-d{
  width:96% ;
  height:100vh;    
  /* display:flex; */
 
}
.upcom-wrapper-d{
   width:100%;     
   height:100vh;
   /* border-right: 1.5px solid #CACACA; */
   background-color: #F6F3EF;
   font-family: "Inter", sans-serif;
   display:flex;
   /* overflow: hidden; */
}
/*.upcom-header-container{
   height:14vh;
}*/
.upcom-header-container-d{
/* height: 60px; */
text-align: left;
border-bottom: 1.5px solid #CACACA;
}
.upcom-left-container-d{
background-color: #F6F3EF;
/* border-right: 1.5px solid #CACACA; */
width:18%; 
}

.upcom-header-d{
  padding: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
   font-family: "Inter", sans-serif;
   font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    color: #0A0A0A;
}
.upcom-match-container-d{
   /* height:86vh;
   overflow-y: auto; */
   height: calc(100% - 54px);
   overflow: auto;
}
.upcom-match-container-d::-webkit-scrollbar{
   width: 2px;
}
.upcom-match-container-d::-webkit-scrollbar-thumb{
   background-color: #025A4E;
   border-radius: 2px;
}

/*******************************************************/

.upcom-center-container{
   /* display:flex;
   flex-direction: column; */
   /* width:82%; */
   width:82%;
   background-color: #EDE7DE;
   border-width: 0px 1px;
   border-style: solid;
   border-color: #CACACA;
   height: 100vh;
   overflow-y: hidden; 
}
.highlight-container{
  padding:16px;
  background-color: #F6F3EF;
  width:100%;
  /* line-height: 1.6; */
}
.upcom-right-container{
   width:15%;
   height:100vh;
   background-color: #F6F3EF;
   padding:0px 16px;
   font-family: "Inter", sans-serif;
}

.report-container{ 
   padding:16px;
   height:80vh;
   /* height:90%; */
}

/* -------------------------------------------------- */
/* Subscription Modal */
.subscription-modal .MuiDialog-paper {
  border-radius: 8px;
  background-color: #F6F3EF;
  width: 1500px;
}

@media only screen and (min-width:1025px){
  .upcom-header-d{
    font-size: 18px;
  }
  .upcom-header-container-d{
    height: 74px;
   }
   .upcom-match-container-d{
      height: calc(100% - 74px);
   
  }
}

/* .modal-title {
  text-align: center;
  background-color: #F6F3EF;
  font-weight: bold;
} */

/* .trial-icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
} */

/* .modal-header {
  font-weight: bold;
  margin-top: 8px;
} */

/* .subscription-modal-content {
  background-color: #F6F3EF;
} */

/* .modal-text {
  margin-bottom: 16px;
  text-align: left; 
  color: #8d8d8d;
  padding-bottom: 20px;
} */

/* .card-container {
  display: flex;
  gap: 16px;
  border-radius: 20px;
  font-family: inter;
} */

/* .subscription-card  {
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border: none;    
} */

/* .plan-type {
  text-align: left;
  color: #00867C;
}
.plan-features {
  text-align: left;
  color: #00867C;
}  
.whats-included  {
  text-align: left;
  font-weight: bold;
  padding-top: 16px;
  padding-bottom: 16px;
}
 */
/* .plan-price{
  text-align: left;
  color: #00867C; 
} */
/* 
.price-amount {
  font-weight: bold;
  font-size: 30px;
}

.plan-duration {
  font-size: 14px;
  color: #666;
}


.divider {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 20px;
}

.plan-features {
  list-style-type: circle;
  padding-left: 20px;
}

.plan-features li {
  font-size: 16px;
  color: #333; 
  line-height: 1.6;     
  position: relative; 
  list-style: none;     
  padding-top: 10px;
}

.plan-features li::before {
  content: '•'; 
  color: #00867C; 
  font-size: 24px; 
  vertical-align: middle;
  line-height: 10px; 
  position: absolute;
  left: -10px;     
} */


/* -------------------------------------------------- */

/* .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */
