
.modal-actions {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.cancel-button {
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background-color: #fff;
  color: black;
  text-transform: none;
  border: 1px solid #00876c; /* Combine border width, style, and color */
  padding: 10px 20px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  font-size: 16px;
}

.cancel-button:hover, .cancel-button:focus {
  box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5);
}

.proceed-button {
  border-radius: 40px;
    background-color: #00876c;
    color: white;
    text-transform: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: box-shadow 0.3s, background-color 0.3s;
    height: 50px;
    font-size: 16px;
}

.proceed-button:hover {
  background-color: #007256; /* Slightly darker green on hover */
  box-shadow: 0 0 8px 3px rgba(0, 135, 108, 0.5);
  /* box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5); */
}

.proceed-button:focus {
  box-shadow: 0 0 12px 4px #00876c; 
  /* box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5); */
}
