.player-card-detail-section{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom:15px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}
/* ----------------------------------------- */
.player-card-detail-match {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 8px 0px;
    /* border-bottom: 1px solid #E0E0E0; */
    border-bottom: 1px solid #00876c;
    cursor: pointer; /* Changes cursor to pointer on hover */
}

.player-card-detail-match:hover {
    color: #555; /* Darkens the color on hover */
    background-color: #f5f5f5; /* Changes background color on hover */
}

.player-card-detail-match-right {
    display: flex;
    align-items: center;
}
/* -------------------------------------------- */
.player-card-detail-match-left{
    display:flex;
    align-items: center;
    gap:8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;
    font-weight: 400;
}

.player-card-team{
    font-weight: 600;
    color: #0A0A0A;
    /* width:100%; */
}
.player-card-dot{
    font-weight: 700;
    font-size: 18px;
    line-height: 69.5%;
}
.player-card-player-detail{
    display:flex;
    justify-content: space-between;
    padding:16px 0px;
}

.player-card-player-parm{
    display:flex;
    flex-direction: column;
    gap:4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;
}
.player-card-player-parm span{
    font-weight: 500;
    color: #0A0A0A;
}
.player-card-bat-text{
    padding: 4px 0px;
    background-color: #A8A8A8;
    border-radius: 3px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
}
.player-card-over-details{
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    /* gap:16px; */
}
.player-card-over-items{
    display:flex;
    justify-content: space-between;
    align-items: center;
    /* gap:50px; */
    /* flex:1; */
    /* flex-direction: column; */
    /* gap:16px;   */
}
.player-card-over-items .pc-text{
    width:50%;
    padding:16px 0px;
    font-weight: 400;
    /* color: #7A7A7A; */
    color: #0A0A0A;
    
}
.player-card-over-items p{
    font-weight: 500;
    color: #0A0A0A;
}