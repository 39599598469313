.land-content-container{
    margin-top:30px;
    text-align: center;
}
.land-content-wrapper{
    padding: 0px 20px;
}
.how-works-text{
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;
    color: #0A0A0A;
    text-align: center;
    margin-bottom:40px;
}

.how-works-text span{
    color:#00876C;
}

.land-works-container, .land-works-container3{
    width:100%;
    height: 430px;
    padding:24px 16px;
    margin-top:20px;
    position:relative;
    /* z-index: 50;  */
    background-color: #F6F3EF;
    border-radius: 16px;
    
}
.land-works-container .numbering, .land-works-container3 .numbering{
    width: 32px;
    height: 32px;
    background-color: #0A0A0A;
    border-radius: 50%;
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:15px;
}
.land-download-text{
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #0A0A0A;
    width:75%;
    text-align: left;
}

.land-matchreport-img{
    position: absolute;
    right:0;
    left:0;
    margin:auto;
    bottom:0px;
    height:238px;
    margin-top:50px;
    width:80%;
    
}
.land-aichat-img{
    position: absolute;
    right:0px;
    bottom:80px;
    width:80%;
    height:126px;
}
.land-payerlist-img{
    position: absolute;
    left:0;
    right:0;
    margin:auto;
    bottom:0px;
    width:80%;
    height:238px;
}
.land-works-images{
    width:100%;
    height:100%;
}
.land-fantasy-container{
    background-image: url(../../../public/images/common/cricket-ground.svg);
    background-size:contain;
    background-repeat: no-repeat;
    background-position:top;
    padding: 30px 20px;
    margin-top:80px;
}
.land-fantasy-text{
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0A0A0A;
    margin-top: 100px;
}

.land-fantasy-container .fantasy-desc-text{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0A0A0A
}
.fantasy-desc-text{
    margin-bottom: 40px;
}

.land-report-img, .land-report-img-mobile{
    display:block;
    width:50%;
    height:210px;
    margin-left: auto;
    margin-right:auto;
    margin-top:15px;
}
.land-fantasy-list{
    width:60%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top:40px;
} 
.land-fantasy-icons{
    width:32px;
    height:32px;
}
.land-fantasy-textone{
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0A0A0A;
    margin-top:12px;
}
.land-fantasy-texttwo{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0A0A0A;
}
.land-feature-desktop{
    display:none;
}
.land-ai-chat-text{
    color:#00876C;
}
.land-start-btn-wrapper-mobile{
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:12px;
    margin-top: 60px;
    
}

.land-start-btn-wrapper-mobile button,
.land-start-btn-wrapper-desktop button{
    width:50%;
    padding: 12px 24px;
    background-color: #00876C;
    border-radius: 1000px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    border:none;
}
.land-start-btn-wrapper-mobile span,
.land-start-btn-wrapper-desktop span{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #7A7A7A;
}
.land-start-btn-wrapper-desktop{
    display:none;
}
.land-pricing-container{
    margin-left: auto;
    margin-right:auto;
    padding: 0px 20px;
}
.land-sub-text{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0A0A0A;
}
.price-card-container{
    margin-top: 40px;
}
.price-card{

    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    /* height:316px; */
    height:400px;
    padding: 32px 24px;
    background-color: #F6F3EF;
    border-radius: 8px;
    margin-top: 20px;
    overflow: hidden;
}

.land-price-top-text{
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #00876C;
    padding-bottom: 12px;
}
.price-wrapper{
    display:flex;
    align-items:flex-end;
}
.land-price-amt{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-right:2px;
}
.price-wrapper .land-month-text{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    letter-spacing: 0.01em;
    padding-bottom:3px;
}
.land-price-btn{
    width:100%;
    border:none;
    padding: 12px 24px;
    background-color: #00876C;
    border-radius: 1000px;
    font-family: 'Plus Jakarta Text', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #F6F3EF;
    text-align: center;
    margin-top:28px;
    margin-bottom: 20px;
    cursor: pointer;
}
.land-included-text{
    border-top: 1px solid #E0E0E0;
    padding-top:20px;
    width:100%;
    text-align: left;
    margin-bottom: 20px;
    
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    
}
.land-other-text{
    font-family: 'Plus Jakarta Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    text-align: left;
    list-style-position: inside;
    text-indent: -1.3em;
    padding-left:25px;
}
/*
@media only screen and (min-width:374px){
    .land-select-player{
        position: absolute; 
        width: 330px;
    }
} */
@media only screen and (min-width:768px){
    
    .how-works-text{
        font-size: 48px;
        line-height: 58px;    
    }
    .land-works-container{
        padding:28px;
    }
    .land-pricing-container{
        margin-left: auto;
        margin-right:auto;
        text-align: center;;
    }
    .price-card{
        width:60%;
        height:582px;
        margin-left: auto;
        margin-right: auto;
    }
    .land-download-text{
        font-size: 40px;
        line-height: 44px;
        width:50%;
    }
    
    .land-fantasy-text{
        font-size: 48px;
        line-height: 58px;
        margin-top: 80px;
    }
    .land-features-container{
        width:80%;
        margin-left: auto;
        margin-right: auto;
    }
    .land-features-contlist{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .land-fantasy-list{
       margin-top:60px; 
       width:50%; 
       padding: 20px 50px;
    }
    
    .land-fantasy-textone{
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }
    .land-price-top-text{
        font-size: 28px;
    }
    .land-price-amt{
        font-size: 48px;
        line-height: 48px;     
    }
    .land-price-btn{
        font-size: 18px;
        line-height: 21px;
    }
    .land-included-text{
        font-size: 18px;
        line-height: 21px;
    }
    .land-other-text{
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }
}

@media only screen and (min-width : 1024px) {
    .how-works-text{
        margin:60px 0px;
    }
    .land-works-desktop{
        display:flex;
        gap:20px;
    }
    .land-content-works{
        display:flex;
        gap:20px;
        width:66%;
    }
    .land-works-container3{
        width:33%;
    }
    .land-works-container .numbering, .land-works-container3 .numbering{
        width: 48px;
        height: 48px;
    }
    .land-download-text{
        font-size: 20px;
        line-height: 26px;
        width:60%;
    }
    .land-fantasy-container{
        background-image: url(../../../public/images/common/cric-pitch-desktop.svg);
        background-size:stretch;
        background-repeat: no-repeat;
        background-position:top;
        margin-top:80px;
        padding: 100px 100px 300px 100px;
    }
    .land-fantasy-text{
        font-size: 36px;
        font-weight: 700;
        line-height: 47px;
        letter-spacing: 0.02em;
        width:75%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
    }
    
    .land-features-container{
        display:flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        gap:10px;
        
        /* margin-top:50px;  */
    }
    .land-features-contlist{
        display:flex;  
        align-items: center;   
        flex:1;
        gap:50px;
    }
    .land-fantasy-list{
        margin:0px;
        padding:10px 0px;
        height:150px;
    } 
    .land-fantasy-icons{
        width: 48px;
        height:48px;
    }
    .land-fantasy-textone{
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
    }
    .land-fantasy-texttwo{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width:70%;
        margin:auto;
        margin-top:3px;
    }
    .land-feature-desktop{
        display:block;
    
        /* height:300px; */
        width:40%;
        /*height:390px;
        margin-left: auto;
        margin-right:auto;
        margin-top:0px; */
    }
    .land-report-img{
        width:100%;
        height:100%;
    }
    .land-start-btn-wrapper-mobile{
        display:none;
    }
    .land-start-btn-wrapper-desktop{
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:12px;
        margin-top: 60px;
    }
    .land-start-btn-wrapper-desktop button {
        width:150px;
        font-size: 16px;
        line-height: 20px;
    }
    .land-feature-contone{
        padding-top:50px;
    }
    .land-feature-conttwo{
        padding:0px 100px;
    }
    .land-report-img-mobile{
        display:none;
    }
    .land-pricing-container{
        width:80%;
        margin-bottom:100px;
    }
    .land-sub-text{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .price-card-container{
        display:flex;
        gap:32px;
        margin-top:0px;
    }
    .land-price-top-text{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }


    
    
}