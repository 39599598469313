.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: 'Inter', sans-serif;
}

.error-modal-content {
  position:relative;
  background-color: #F6F3EF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 25%;
  max-width: 40%;
  /* max-width: 500px; */
  text-align: center;  
  line-height: 2;
  font-size: 14;
}

.modal-header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 20px; 
}

.modal-close-icon {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 20px;
}

.modal-close-icon img {
  width: 20px;
  height: 20px;
  cursor: pointer; 
}

.modal-close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close-button:hover {
  background-color: #0056b3;
}

.error-modal-content p{
color: black;
margin: 15px 0; 
font-weight: 500;
}

.error-modal-content p a{
display: inline;
}