.approach-container{
    background-color: #EDE7DE;
    padding:24px 16px;
    padding-bottom: 60px;
    min-height:100vh;
    position:relative;
}
.approach-options{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F6F3EF;
    border-radius: 8px;
    margin-top:15px;
    padding:16px 16px;
}
.approach-choose{
    display:flex;
    align-items: center;
    gap:10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}

.approach-info-icon{
    height: 25px;
}
.approach-info-icon:hover + .approach-tooltip{
    display:block;  
    
}
/* .approach-tooltip {
    display: none;
    position: absolute;
    background-color: #ffffff;
    right: 20px;
    left: 20px;
    top: 60px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 8px 16px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: justify;
    width: 80%;
    line-height: 1.6;
    z-index: 1000; 
  } */
  .approach-tooltip {
    position: absolute;
    right: 20px;
    left: 20px;
    top: 60px;
    margin: 0 auto;
    background-color: #F6F3EF;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    z-index: 1000;
    font-family: inter;
    line-height: 1.6;
    text-align: justify;
    width: 80%;
    border-radius: 20px;
    padding: 20px;
    color: #8D8D8D;
  }
  .tooltip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 20px;
    /* margin-right: 20px; */
  }

.appr-one-best-guess-wrapper{
    padding-top:16px;
}
.appr-sub-text{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
    margin-top: 5px;
}
.approach-next-btn{
    display:block;
    position:fixed;
    left: 50%;
    transform: translate(-50%);
    right:50%;
    bottom: 30px;
    width: 20%;
    height: 35px;
    background-color:#A8A8A8;
    padding: 10px 18px;
    border-radius: 1000px;
    border:none;
    color:#FFFFFF;
    cursor: pointer;
}
.approach-next-btn-actv{
    background-color: #00876C;
    box-shadow: 0px 0px 48px rgba(2, 133, 115, 0.43);
}

.explain-close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }