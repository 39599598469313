.chat-overlay.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0); /* Slide to its final position */
}


.chat-window {
    width: 98%; /* Updated width */
    height: 90%;
    margin: 10px auto; /* Added auto margin for horizontal centering */
    margin-bottom: 5px;
    padding: 10px;
    background-color: #ede7de;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    
}




.chat-overlay.open .chat-window {
    transform: translateY(0);
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px;
    padding: 10px;
}

.title-and-question {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between text and question icon */
    padding-left: 10px;
}

.title-and-question img{
    height: 20px;
    width: 20px;
}

.help-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative; /* Position relative to place popup next to icon */
}

.help-popup {
    position: fixed;
    top: 50px;
    left: 50px; /* Move popup to the right of the icon */
    max-width: 300px;
    background: #f6f3ef;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1010;
    display: flex;
    flex-direction: column;
}

.close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.chat-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}

.chat-content::-webkit-scrollbar{
    width: 3px;
    height: 3px;
    cursor: pointer;
}
.chat-content::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
}
/* .chat-content::-webkit-scrollbar-thumb {
    background-color: #025A4E;
    border-radius: 2px; 
}
.chat-content::-webkit-scrollbar-track {
    background: #f1f1f1; 
} */


.message {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    margin: 15px;
}

.message-icon {
    width: 30px;
    height: 30px;
}

.message-box {
    color: #333; /* Basic font color */
    font-family: 'Arial', sans-serif; /* Default font family */
    line-height: 1.6; /* Spacing between lines for better readability */
    background-color: #fff; /* Background color of the message box */
    /* padding: 10px; */
    border-radius: 8px; /* Rounded corners for the message box */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-right: 20px;

}

.message-box p {
    margin-bottom: 10px; 
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 8px;
    text-align: justify;
}

.message-box h3 {
    font-size: 1.1em; /* Larger font size for subheadings */
    color: #007256; /* A distinct, readable color */
    margin-top: 20px;
    margin-bottom: 10px;
}

h2 {
    font-size: 1.3em; /* Larger font size for subheadings */
    color: #007256; /* A distinct, readable color */
}

.message-box h4 {
    font-size: 1em;
    color: #000; /* Color differentiation for different levels of headings */
    margin-top: 15px;
    margin-bottom: 5px;
}

.message-box table {
    width: 100%; /* Full-width tables */
    border-collapse: collapse; /* No double borders */
    margin-top: 10px; /* Space above the table */
    background-color: #f8f9fa; /* Slight background color for the table */
}

.message-box th,
.message-box td {
    border: 1px solid #e1e1e1; /* Light border for readability */
    padding: 8px; /* Padding in table cells */
    text-align: left; /* Align text to the left in cells */
}

.message-box th {
    background-color: #f1f3f4; /* Different background for header cells */
    color: #202124; /* Darker text color for contrast */
}

.message-box ol > li {
    list-style-type: none; /* Removes bullets specifically from <li> under <ol> */
}

.message-box ul {
    padding-left: 20px; /* Proper indentation for lists */
}

.message-box li {
    margin-bottom: 5px; /* Space below each list item */
}


.message.user .message-box {
    background-color: #f6f3ef;
}

.message.bot .message-box {
    background-color: white;
}

.chat-input {
    position: relative;
    height: 89px;
    background: #F6F3EF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 10px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
    
}

.chat-input-textarea {
    width: 100%;
    height: 57px;
    background: transparent;
    border: none;
    resize: none;
    padding-right: 40px;
    outline: none;
    border-radius: 8px;
}

.send-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(0, 0, 0, 0.5);   */
    z-index: 10;  /* Adjust z-index as needed */

}

.chat-overlay {
    position: fixed;
    bottom: 0; 
    left: 22%;
    width: 57%; 
    height: 92%; 
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.3s, visibility 0.3s;
    flex-direction: column;
    border-radius: 8px;
    transform: translateY(100%); /* Start below the screen */
}


/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .message-box {
        
        padding-left: 10px;
        padding-right: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        margin-right: 5px;
    
    }
    .message {        
        margin-bottom: 10px; 
        margin: 5px;
    }
    
    .chat-overlay {
        bottom: 0;
        left: 0;
        /* right: 0; */
        width: 100%; 
        height: 90%;
        align-items: flex-end; 
        /* padding-top: 10%;  */
        
    }

    .chat-window {
        width: 100%; /* Full width of the overlay */
        
        margin: 0; /* Remove margins for mobile */
        border-radius: 0; /* Optional: removes border-radius for full-width use */
        padding: 5px; /* Reduced padding on mobile for more space efficiency */
    }
    
}