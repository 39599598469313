
.show-teams-container{
    width:100%;
    min-height:100vh;
    background-color: #EDE7DE;
    position:relative;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    /* padding:16px; */
}
.loading-overlay{
    width:100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: 'rgba(0, 0, 0, 0.5)';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    z-index: 1000
}
.show-teams-noteams{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin-top:-80px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;
}
.show-teams-team-names{
    padding:16px;
}
.show-teams-top-text{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.show-teams-filter-container{
    display:flex;
    gap:40px;
    margin-top: 10px;
}
.show-teams-filter-text{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #0A0A0A;
}
.show-teams-filter-by{
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #585858;
}
.show-teams-filter-wrapper{
    display:flex;
    flex-direction: column;
    gap:5px;
}
.show-teams-filter-choose{
    border: 1px solid #00876C;
    border-radius: 4px;
    display:flex;
    align-items: flex-start;
}
.show-teams-filter-choose span{
    padding: 6px 10px ;
    display:flex;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border-right: 1px solid #00876C;
   
}
.show-teams-filter-choose span:last-of-type{
    border-right:none;
}

.show-teams-filter-selected{
    background-color: #00876C;
    color: #FFFFFF;
}



.teams-selected-container{
    background-color: #F6F3EF;
    border-radius: 8px;
    padding: 16px;
    margin-top:16px;
}
.teams-selected-names{
    background-color: #A8A8A8;
    border-radius: 3px;
    padding: 8px 0px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    display:flex;
    gap:5px;
    justify-content: center;
}
.team-selected-list-wrapper{
    background-color: #F6F3EF;
    border-radius: 8px;
    padding-top:16px;
}
.team-selected-lists{
    display:flex;
    flex-direction: column;
    padding: 10px 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}
.team-selected-bottom-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;
}
