/* .left-panel{
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    padding:16px 14px;
    background-color: #F6F3EF;
    border-right: 1px solid #CACACA;
    width:4%;
    height:100vh;
} */

.left-panel{
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    padding: 16px 14px;
    background-color: #F6F3EF;
    border-right: 1px solid #CACACA;
    width: 4%;
    height: 100vh;
}
.left-panel-wrapper{
    display:flex;
    flex-direction: column;
    gap:40px;
}
.cric-logo{
    width:36px;
    height:36px;
}

.NavigationIconContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.NavigationIconContainer .icons{
     width: 32px;
     height: 32px;
     cursor: pointer;
}

.usericon-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    color: #025A4E;
}
.usericon-container .icons{
    width: 32px;
    height: 32px;
    cursor: pointer;
}
/*
.person-icon{
    margin-bottom: 20px;
}
/*
.icons{
    width:20px;
    height:20px;
    cursor: pointer;
} */

/* Modal for more options */
.user-modal {
    position: absolute;
    bottom: 30px; /* Move the modal up slightly */
    left: 65px;  /* Move the modal to the right */
    width: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
    padding: 15px;
    z-index: 100; /* Ensure it's above other content */
    font-size: 16px;
    /* font-family:  sans-serif; */
    font-family: 'Inter';
}

.user-modal hr {
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: #ccc;
}

.user-modal .separator {
    margin-top: 15px;
    margin-bottom: 5px;
    height: 1px;
    background-color: #eee;
}

.user-modal p {
    margin: 5px 0;
    line-height: 1.5;
    padding-bottom: 5px; /* Reduced padding for a compact look */
}

.user-modal p.key {
    color: #8d8d8d;
    font-size: 12px; /* Consistent with your new style request */
    /* font-weight: bold; */
    font-weight: bolder;
}

.user-modal p.value {
    margin-top: -5px; /* Adjusts space between key and value */
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}

.user-modal .trial-plan {
    background-color: #cce7e2;
    color: #00876c;
    padding: 4px 8px;
    border-radius: 5px;
    display: inline-block;
}

.user-modal .link {
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: block; /* Make each link block for better interaction */
    margin: 5px 0; /* Space between links */
    padding: 5px 0; /* Padding for better touch interaction */
    font-size: 14px;
}

.logout-link {
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: block; /* Make each link block for better interaction */
    margin: 5px 0; /* Space between links */
    padding: 5px 0; /* Padding for better touch interaction */
    color: red;
    font-size: 14px;
}

.user-modal .link:hover {
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 5px;
}
