.approach-one-container-d{
    width:100%;
    font-family: 'Inter';
    font-style: normal;
    /* padding-bottom: 120px; */
}

.player-card {
    background-color: #f6f3ef;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1); 
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* display: flex; */
    /* width: 619px; */
    
    border-radius: 8px;
    /* padding-top: 5px; */
    /* padding:16px 16px; */
    width:93%;
    margin-bottom: 10px;    
    /* font-weight: 500; */
    /* font-size: 14px; */
    /* line-height: 15px; */
    color: #0A0A0A;
    /* min-height: 50px; */
}

.cardContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* padding-bottom: 10px; */
    width: 75%;
}

.player-separator {
    display: block;
    min-height: 1px;  
    background-color: #d3d3d3; 
    /* margin: 10px 20px;  */
    /* padding-left: 50px;  */
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
    width: 90%;
}

.approach-playerName {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
    /* padding-left: 10px; */
    white-space: nowrap; /* Prevent text from wrapping onto the next line */
    overflow: hidden; /* Hide text that goes beyond the element's bounds */
    text-overflow: ellipsis; /* Use an ellipsis to indicate clipped text */
}

.captainContainer{    
    /* padding-bottom: 5px;    */
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Align items to the right */
    align-items: center;
    width: 25%; /* Ensure it takes up full width */
    gap: 10px; /* Maintain spacing between items */ 
    padding-right: 20px;

    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.playerType {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #7A7A7A;
        white-space: nowrap; /* Prevent text from wrapping onto the next line */
        overflow: hidden; /* Hide text that goes beyond the element's bounds */
        text-overflow: ellipsis; /* Use an ellipsis to indicate clipped text */
}

.role-d-circle {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 35px;
    height: 35px;
    border: 1px solid #A8A8A8;
    border-radius: 50%; /* makes the div perfectly circular */
    cursor: pointer; /* indicates the element is clickable */
    user-select: none; /* prevents text selection */
}

.role-d-circle.selected {
    border-color: #00876c; /* change border color when selected */
    color: #00876c; /* change text color when selected */
    background-color: #00876c;
    color: #fff;
}

.approachHeadingContainer{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    color: #0A0A0A;
    margin-bottom: 16px;
}

.approachTeamHeading{    
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;    
}

.approachTeamSubHeading{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;    
}

.playerSerialAndCard{
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    /* padding: 0px; */
    
    width:100%;
    gap:10px
    /* 1 */
}

.playerSerialNumber{
    width:5%;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #7A7A7A;
}

.playerCardsContainer{
    padding-top: 20px;
}

.blackButton-d {
    display: flex; /* Ensures flexbox layout */
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    border-radius: 40px;
    /* background-color: #f6f3ef; */
    background-color: #000;
    color: white;
    text-transform: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    font-size: 14px;
}

.playerDetailContainer{
    display: flex;
    flex-direction: row;
}

.blackButton:hover, .blackButton:focus {
    box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5);
}

.teamName{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2px 4px;
    background: #FFFFFF;
    border-radius: 2px;
    font-family: 'Inter', sans-serif;
    font-size:8px;
    font-weight: 700;
    line-height: 10px;
    background-color: #0A0A0A;
}

.teamStyleBlack {
    background-color: black;
    color: white;    
    /* padding: 10px;  */
    
}
.teamStyleWhite {
    background-color: white;
    color: black;
    font-weight: 700;
}

.approach-team-show-row{
    width:100%;
    display:flex;
    justify-content: space-between;
    gap:10px;
    margin-top: 15px;
}
.tabPanelContainer{    
    /* height: 70vh; */
    height:60vh;
    overflow-y: auto;
    overflow-x: hidden;
    /* width: 700px; */
    /* width: 35vw; */
    width:50%;
    overflow-x: hidden;
    /* border:2px solid #c0bcb5;  */
    padding:16px;
    border-radius:10px;
    padding-bottom:50px;
}

.tabPanelContainer::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
    border-radius: 3px;
  }
.tabPanelContainer::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
    border-radius: 3px;
  }

.ApproachInsightContainer{
    padding:16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
}

.ApproachInsightHeading{
    font-weight: 600;
}
.ApproachInsightContainer p{
    line-height: 1.4;
    text-align: justify;
    margin-top: 10px;
}

.ApproachInsightAndChartContainer{
    display: flex;
    flex-direction: column;
    border: '1px solid #ccc'; 
    border-radius: 5px;
    background-color: #f6f3ef;
    /* margin-top: 10px; */
    /* height: 70%; */
    height:70%;
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
}
.ApproachInsightAndChartContainer::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
  }
.ApproachInsightAndChartContainer::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
  }


.ApproachChartContainer{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    /* margin-top: 10px; */
}

.ApproachInsightAndFirstBattingContainer{
    /* display: flex;
    flex-direction: column;
    width: calc(100% - 40vw);
    margin-left: 40px; */
    width:50%;
    height:65vh;
}

.firstBattingSecondBattingContainer{
    display: flex;
    flex-direction: column;
    border: '1px solid #ccc'; 
    border-radius: 5px;
    background-color: #f6f3ef;
    margin-top: 10px;
    height: 20%;
    padding:16px;
    font-weight: 400;
    font-size: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: justify;
    padding-bottom:70px;
}
.firstBattingSecondBattingContainer::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
}
.firstBattingSecondBattingContainer::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
}

.firstBattingSecondBattingContainer p{
    font-size: 14px;
    line-height: 1.4;    
}

.teamNametxt-d{
    padding: 4px 8px;
    font-size:8px;
    border-radius: 4px;
    font-weight: 700;
    line-height: 10px;
}
.team-recom-team-names-d{
    display:flex;
    align-items: center;
    gap:24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #7A7A7A;
    margin-bottom: 5px;
}

.eachPlayerList-wrapper{
    display:flex;
    flex-direction: column;
    /* padding-right: 40px; */
}
.eachPlayerList{
    display:flex;
    align-items: center;
    gap:5px;
}

@media only screen and (min-height:650px){
    .tabPanelContainer{    
        padding-bottom:0px;
    }
    .firstBattingSecondBattingContainer{
        padding-bottom:0px;
    }
}