
.report-container-top{
    display:flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    padding:24px; 
}
.user-icon{
    width:88px;
    height:60px;
}
.report-head{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 8px;
}
.report-step{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    width:80%;
}
.report-step-num{
    display:flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:10px;
}

.step-num{
    font-family: "Public Sans", sans-serif;
    width:32px;
    height:32px;
    border-radius: 50%;
    border:0.82px solid #025A4E;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    line-height: 15px;
    color: #025A4E;

    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:5px;

}
.report-step-text{
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}
.report-container-center{
    margin-top: 15px;
    display:flex;
    gap:24px;
    font-family: "Inter", sans-serif;
}
.report-center-left, .report-center-right {
    background-color: #FFFFFF;
    display:flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    width:49%;
}
.report-center-toptext{
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
}
.report-center-text{
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #797979;
    margin-bottom: 32px;
}
.center-download-report-btn{
    /*padding: 10px 20px; */
    padding:0.8em 1.3em;
    background: #025A4E;
    border-radius: 8px;
    /*width: 170px;
    height: 39px;*/
    width:50%;
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    /*line-height: 19px; */
    line-height:1.1;
    color: #FFFFFF;
    border:none;
    cursor: pointer;
}
.quest-link{
    padding:10px 0;
    width: 124px;
    height: 39px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #025A4E;
    text-decoration: none
}
.report-container-bottom{
    width:100%;
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 32px;
    padding:16px;
    border-radius: 8px;
    background-color: #F6F3EF;
}
.quest-textarea{
    border:none;
    outline:none;
    resize:none;
    border-radius: 8px;
    padding:8px;
    width:100%;
    background-color: transparent;

    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color:#000000;
}
.quest-textarea::placeholder{
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #777777;
}
.send-icon{
    width:17px;
    height:16px;
    color:#777777;
    margin-top:8px;
    flex:1;
    cursor: pointer;
}
.quest-textarea::-webkit-scrollbar{
    width: 2px;
}
.quest-textarea::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
}
