.root2 {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #EDE7DE;
    flex-direction: column;
    overflow-x: hidden;
}

.content {
    width: 70%;
    background-color: #F6F3EF;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    border-radius: 25px;
    line-height: 2;
    display: flex;
    flex-direction: column;
}

.logo_static {
    display: block;
    margin: 20px auto;
    max-width: 70%;
    max-height: 65%;
    height: auto;
}

.section {
    margin-bottom: 40px;
}

.footer {
    width: 100%;
    background-color: #000;
    color: white;
    padding: 10px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.logoFooter {
    display: inline-block;
    width: 100px;
    height: auto;
}

.footerText {
    margin-left: 20px;
}

header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
}

.nav-links-2 {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: nowrap;
}

.nav-links-2 a {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #0A0A0A;
    margin-right: 30px;
    text-decoration: none;
    flex-wrap: nowrap;
}

.login-btn-2 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #00876C;
    padding: 12px 24px;
    border: 2px solid #00876C;
    border-radius: 1000px;
    cursor: pointer;
    background: none;
}

.hamburger {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    border-radius: 5px;
}

@media (max-width: 600px) {
    .content {
        width: 90%;
        padding: 10px;
    }

    .nav-links-2 {
        position: absolute;
        top: 60px;
        right: 0;
        background-color: white;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: none;        
    }

    .nav-links-2.open {
        display: flex;        
        border-radius: 20px;
        background-color: #f6f3ef;
        margin-right: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .hamburger {
        display: flex;
    }
}
