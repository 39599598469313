/* .insight-container {
  /* height: 800px; 
  height: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden; 
} */
/*
.insight-container {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure this is properly set */
    /* overflow-x: hidden; */
    /* overflow-y: auto; This might be redundant if children scroll individually */
  /*  background: #F6F3EF;
    width:100%;
    border-radius: 8px;
    margin-top:15px;
    padding-bottom: 60px;
  } */
    
    /* .chart-panel {
      height: 100%;
      padding: 10px;
      border-right: 1px solid #eee;
    } */
    /* .chart-panel {
      height: 100%;
      padding: 20px;
      border-right: 1px solid #eee;
      font-family: 'Public Sans', sans-serif;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.1);
      overflow-x: hidden; 
  } *//*
  .chart-panel{
    border: 2px solid #EBEBEB;
    border-radius: 12px;
    margin:10px;
  } */
/*
  .chart-panel h6 {
      margin-bottom: 20px;/*Space below the title*/
      /* color: #333; Dark color for better readability */
      /* font-size: 1.25rem; Larger font size for headings */
      /* font-weight: bold; Bold font weight for headings */
   /*   font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #0A0A0A;
  } */
  
  /* Additional styling for no data message */
  /*.chart-panel .MuiTypography-root {
      color: #666; /* Softer color for messages */
  /*    text-align: center; /* Center-align the text */
  /*    margin-top: 20px; /*Push the message down to center vertically*/
  /* } */
    
  /* .data-panel {
    flex: 1;  /* Ensures it fills the space in flex container */
    /* padding: 15px; */
    /* height: 60%; */
    /* background-color: #FFFFFF; */
    /* overflow-y: auto; Ensures scrollbar appears if content overflows */
  /* } */
  
  /* .chart-panel { */
    /* flex: 1;  Allows each panel to grow and fill the container */
    /* overflow-x: hidden; */
    /* overflow-y: hidden; Allows vertical scroll if content overflows */
  /* } */
   /* 
  .data-panel div {
    /* font-family: 'Public Sans', sans-serif; */
  /*  font-family: 'Public Sans', sans-serif;
    font-style: normal;
    /* font-weight: 600; */
  /*  font-size: 16px;
    line-height: 19px;
    color: #0A0A0A;
    line-height: 1.8;
    text-align: justify;
    padding-bottom:60px;
  }
  .data-panel h6, .data-panel h2 {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #333333;
    /* font-size: 1.25rem; */
  /*  font-size: 20px;
    font-weight: bold;
  } */
 
  
  .custom-tooltip {
    background-color: #fff; /* White background for clarity */
    border: 1px solid #ddd; /* Subtle border */
    padding: 10px 15px; /* Padding inside the tooltip */
    border-radius: 5px; /* Rounded corners for a smoother look */
    box-shadow: 0 3px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
    font-family: 'Public Sans', sans-serif; /* Consistent font with the rest of the UI */
    font-size: 0.9rem; /* Slightly smaller font size for tooltips */
    color: #333; /* Darker text for readability */
    text-align: left; /* Align text to the left */
    line-height: 1.4; /* Line height for better readability */
  }
  
  .custom-tooltip .label {
    font-weight: bold; /* Bold for the match label */
    color: #025A4E; /* Use a distinct color to highlight match name */
    margin-bottom: 5px; /* Space below the match name */
  }
  
  .custom-tooltip .intro, .custom-tooltip .desc {
    margin-bottom: 2px; /* Small space between lines for compact display */
  }
  
  .custom-tooltip .desc {
    color: #555; /* Slightly softer color for descriptions */
    font-style: italic; /* Italicize descriptions for style */
  }
 /*-----------------------------------------*/
 .insight-container{
    background-color: #F6F3EF;
    padding:5px 16px;
    width:100%;
    border-radius: 8px;
    margin-top:15px;
    padding-bottom: 60px;
 } 
 .insight-chart-container{
    border: 2px solid #EBEBEB;
    border-radius: 12px;
    margin:10px 0px 20px 0px;
    padding:10px 0px;
 }
 .insight-chart-container p{
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #0A0A0A;
 }
 
 .insight-text-wrapper{
    padding:15px 0px;
  } 
  .insight-content{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    color: #0A0A0A;
  }
  .insight-content h2, h3{
    margin-bottom: 5px;
    color: #666;
  }
  
  .insight-content h3{
    margin-top:15px;
  }
  .insight-content p{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    text-align: justify;
  }
  .insight-content p:last-of-type{
    margin-top:20px;
  }
  
  .insight-content li {
    font-family: 'Inter', sans-serif; /* Consistent font family */
    font-size: 14px; /* Appropriate font size for list items */
    line-height: 20px; /* Slightly more line height for readability */
    color: #333; /* Darker color for better readability */
    margin-bottom: 0.5em; /* Space below each list item */
    list-style-type: disc; /* Style of the bullet points */
    list-style-position: inside; /* Position of bullet inside the content flow */
    padding-left: 1em; /* Padding to the left for indentation */
    text-align: justify;
}
.insight  strong {
  font-weight: bold; /* Bold font weight for emphasis */
  color: #0A0A0A; /* A distinct color to highlight important text */
}