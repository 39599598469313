.match-insights-container{
    padding:16px;
    background-color: #EDE7DE;
    min-height: 100vh;
}
.spinner-div{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}
.insights-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.insights-text{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0A0A0A;
}
.insights-lang-block{
    display:flex;
    align-items: center;
    gap: 3px;
    background: #F6F3EF;
}
.insights-lang-eng{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
    padding: 4px 10px;
    cursor: pointer;
}
.insights-lang-hindi{
    font-family: 'Noto Sans Devanagari';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 106.1%;
    color: #0A0A0A;
    cursor: pointer;
    padding: 6px 10px;
}
.insight-lang-bg{
    background: #0A0A0A;
    border-radius: 4px;
    color: #FFFFFF;
}
.insight-bld-team-btn{
    width: 30%;
    height: 39px;
    left: calc(50% - 147px/2 - 0.5px);
    bottom: -22px;
    background-color: #00876C;
    box-shadow: 0px 0px 48px rgba(2, 133, 115, 0.43);
    border-radius: 1000px;
    padding: 12px 20px;
    border:none;
    color:#FFFFFF;
}
/* .insight-panel-container{ */
    /* width:100%; */
    /* background: #F6F3EF; */
    /* border-radius: 8px; */
    /* margin-top:15px; */
/* } */