.right-panel-top-text{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 10px;
    /* padding-top:5px; */
    /* padding-bottom:16px; */
}
.players-text-block-d{
    display:flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    background-color: #D5E5FF;
    border-radius: 4px;
    /* margin: 16px 0px; */
}
.players-text-block-d span{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
}

.load-team-name{
    display: flex;
    border: 1px solid #A8A8A8;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #A3A3A3;
    border-radius: 8px;
    padding:4px;
    /* gap:6px; */
    margin-bottom:5px;
}
.player-teamname{
    width:50%;
     text-align: center;
     cursor: pointer;
     border-radius: 8px;
     padding: 4px 4px;
     color: #0A0A0A;
 }
 .right-panel h5 {
    background-color: #A8A8A8; /* Set the background color to grey */
    color: white; /* Set text color to white */
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center; /* Center-align the text */
    font-weight: normal; /* Ensure the text is not bold */
    padding: 4px 0px;/* Add some padding to make the background visible around the text */
    border-radius: 3px; /* Optionally, add rounded corners */
    margin:5px;
}

.player-count {
    padding: 0px 7px;
    background-color: #025A4E; /* Green background color */
    border-radius: 8px; /* Rounded corners */
    color: white; /* White text color */
    width: 28px;
    height: 17px;
    /* padding: 3px 6px; Padding around the text for better visual spacing */
    /* margin-left: 5px; Space from the team name */
}

.player-box {
    background-color: #F6F3EF; 
    padding: 10px 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    height: 50vh;   
  }
  /*
  .load-player-list{
    font-size: 10px;
    /* height:65vh; */
    /* padding:5px; */
    /* overflow-y: auto; */
/*} */

.load-player-list::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
}
.load-player-list::-webkit-scrollbar-thumb{
    background-color: #025A4E;
    border-radius: 2px;
}

  .played-last-match-container {
    min-height:0vh;
    max-height: calc(50vh - 60%);
    overflow-y: auto; 
    overflow-x: hidden;
    /* padding-bottom: 20px; Spacing between sections */
}

.rest-of-squad-container {
    min-height: 0vh;
    max-height: calc(50vh - 75%);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom:5px;
}


.load-player {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 10px;
    font-family: "Inter", sans-serif;
    border-radius: 10px; /* Ensures the outer container has rounded corners */
}

.load-player-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    transition: background-color 0.1s ease, transform 0.1s ease;
    border-radius: 10px; /* Rounded edges for hover effect */
}

.load-player-content:hover {
    background-color: #ede7de; /* Light tan background on hover */
    transform: scale(1.02); /* Slightly enlarge on hover for emphasis */
}

.right-player-name, .player-type-icon {
    transition: transform 0.1s ease; /* Smooth transition for transformations */
}


.player-selection-icon {
    border: none;
    cursor: pointer;
}

.player-selection-icon:hover {
    background-color: transparent; /* Ensure no hover effect on the icon button */
    transform: none;
}

/* ---------------- */
/* .data-chart-container {
    max-height: 600px; 
    overflow-y: auto; 
} */



.player-type-icon {
    transition: transform 0.1s ease; 
}

.load-report{
    width:100%;
    display:flex;
    flex-direction: column;
    padding: 16px; 
    background: #FFFFFF;
    border-radius: 8px;
}
.right-report-center-toptext{
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.right-report-step-text{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #797979;
    margin-bottom: 24px;
}
.right-download-report-btn{
    padding: 10px 20px;
    background: #025A4E;
    border-radius: 8px;
    border: none;
    outline: none;
    
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    width: 106px;
    height: 36px;
}

.load-players-container{
    margin-top: 10px;
    
}



.bg-team-white-d{
    background: #FFFFFF;  
}


.player-pic{
    width: 24px;
    height: 24px;
}
.right-player-name{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;  
    /* display: inline-block; */
    /* padding-left: 20px; */
    /* padding-right: 20px; */
}
.player-type-icon{
    width: 18px;
    height: 18px;
    /* margin-left: 10px; Adds space between the name and the icon */
    /* vertical-align: middle; Aligns the icon with the middle of the text */
}
.player-icons{
    margin:10px;
}
.load-player-left{
    display: flex;
    align-items: center;
    gap:5px;
    /* padding: 5px 0px; */
}
.load-player-right{
    display:flex;
    justify-content: center;
    align-items: center;
}

.right-panel-icons{
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #525252;
}

.wicketkeeper-icon-large {
    width: 36px; /* Adjust the size according to your design */
    height: auto;
}

.highlight-player {
    background-color: #baeddc;  
    border-radius: 8px; 
    padding: 10px;  
    margin-bottom: 10px;  
    transition: width 0.3s ease; 
    overflow: hidden;
}

.select-player-modal-container {
    width: 30vw;
    height: 100vh;
    position: fixed;
    /* top: 10%;   */
    left: 70%; 
    transform: translate(-50%, -50%); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* overflow: auto;  */
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Grey out the background */
    display: flex;
    justify-content: center;
    align-items: center; /* These ensure the modal content is centered both vertically and horizontally */
    z-index: 1000; /* Ensures it sits on top of other content */
}

@media only screen and (min-height: 600px){
.player-box {
    /* height: 66vh;    */
    height:58vh;
    /* margin-bottom: 20px; */
}
.played-last-match-container {
   
    min-height:0vh;
    max-height: 25vh;
}

.rest-of-squad-container {
    
    min-height: 0vh;
    padding-bottom:0px;
    /* height:20vh; */
    height:15vh;
    /* max-height:20vh; */
    max-height: 28vh;
}
.load-team-name{
    font-size: 12px;
    line-height: 15px;
    border: 1px solid #A3A3A3;
    border-radius: 8px;
    padding:6px;
    /* gap:6px; */
    margin-bottom:15px;
}
.player-teamname{
     padding: 8px 4px; 
 }
 .right-panel h5 {
    margin: 10px 0; /* Add some margin for spacing */
}
}


.slide-left {
    animation: slide-left 0.5s ease-out forwards;
}

@keyframes slide-left {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}



.slide-right {
    animation: slide-right 0.5s ease-out forwards;
}

@keyframes slide-right {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}
