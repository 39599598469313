.login-container{
    width:100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 32px 16px;
    background-image: ('/images/mobile/bg-img.svg');
    background-position: bottom;
    max-width: 500px;

    
}
.login-err-text{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
    display:flex;
    gap:2px;
    align-items: center;
    
}
.otp-alert-img{
    width:16px;
    height:16px;
}
.login-toptext{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top:30px;
}
.login-bottomtext{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7A7A7A;
    margin:5px 0px 30px 0px;
}
.login-form{
    display:flex;
    flex-direction: column;
    gap:20px;
}
.login-inp-blocks{
    display:flex;
    flex-direction: column;
    gap:10px;
}
.input-group-mobile{
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 12px 16px;
}
.register-label-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}
.reg-asterisk{
    color: #C84141;
    margin-left: 2px;
}
.opt-text-color{
    color: #7A7A7A;
}
.login-inp{
    border: none;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
.register-terms-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #0A0A0A;
    text-align: center;
    margin-top:50px;
    padding:0px 20px;
}
.register-links{
    color: #63b2f0;
}
.login-btn, .verify-btn {
    display: flex;
    justify-content: center;
    align-items: center; /* This will vertically and horizontally center the spinner */
    padding: 16px;
    background-color: #00876C;
    border-radius: 1000px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-top: 15px;
    cursor: pointer;
    width: 100%; /* Ensures the button stretches to the full width of its container if needed */
}




.register-login{
    width:100%;
    text-align: center;
    margin-top: 35px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;

}
.register-login-text{
    color: #00876C;
    margin-left:8px;
    cursor: pointer;
}

.otp-input {
    width: 40px; /* Fixed width */
    height: 40px; /* Fixed height */
    text-align: center;
    font-size: 16px;
    border: 2px solid #cccccc;
    border-radius: 4px;
    margin: 0; /* Adjust or remove margin as per layout needs */
  }
  
  /* Style for focusing on OTP inputs */
  .otp-input:focus {
    border-color: #007bff;
    outline: none;
  }
/* .verify-btn {
    margin-bottom: 20px; 
    padding: 16px;
    background-color: #00876C;
    border-radius: 1000px;
    border:none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-top: 15px;
    cursor: pointer;
} */

.separator-line {
  border-top: 1px solid #cccccc; /* Thin grey line */
  margin: 20px 0; /* Space above and below the line */
}

.otp-form {
    display: flex; /* Aligns children (OTP inputs) in a row */
    justify-content: center; 
    flex-direction: column;
    flex-wrap: nowrap; /* Prevents wrapping to ensure inputs stay in one line */
    gap: 10px; /* Space between inputs */
  }


  .timer {
    text-align: right; /* Aligns text to the right */
    font-weight: bold; /* Makes the font bold */
    color: #00876C; /* Specific green shade */
    margin-top: 10px; /* Space above the timer */
  }
  
.otp-inputs-container {
    display: flex; /* Ensure inputs are lined up horizontally */
    justify-content: left; /* Center the inputs horizontally */
    gap: 10px; /* Space between each input */
  }