.no-upcoming-matches {
    font-family: 'Inter', sans-serif; /* Applying font globally to all child elements */
    background-color: #EDE7DE;
    width: 100%;
    height: 100vh; /* Adjust based on your design needs */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    text-align: center; /* Ensures text within children elements is centered */
}

.no-upcoming-matches h1 {
    /* width: 243px;
    height: 27px; */
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #0A0A0A;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 20px;
}

.no-upcoming-matches p {
    /* width: 410px;
    height: 36px; */
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #7A7A7A;
    text-align: center;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 20px;
}
