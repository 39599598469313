.register-toptext{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-top:30px;
}
.register-bottomtext{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7A7A7A;
  margin:5px 0px 30px 0px;
}
.register-form{
  display:flex;
  flex-direction: column;
  gap:20px;
}
.register-inp-blocks{
  display:flex;
  flex-direction: column;
  gap:10px;
}
.register-label-text{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0A0A0A;
}
.reg-asterisk{
  color: #C84141;
  margin-left: 2px;
}
.opt-text-color{
  color: #7A7A7A;
}
.register-inp{
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 12px 16px;
  outline:none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.login-input-group {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 12px 16px;
}

.input-group-text {
font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
padding-right: 8px;
color: #7A7A7A;
} 
.register-inp-mob{
    border: none;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
.register-terms-text{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #0A0A0A;
  text-align: center;
  margin-top:50px;
  padding:0px 20px;
}
.register-links{
  color: #63b2f0;
}
.register-btn{
  padding: 16px;
  background-color: #00876C;
  border-radius: 1000px;
  border:none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin-top: 15px;
  cursor: pointer;
}
.register-login{
  width:100%;
  text-align: center;
  margin-top: 35px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0A0A0A;

}
.register-login-text{
  color: #00876C;
  margin-left:8px;
  cursor: pointer;
}

.sigunup-container{
  max-width: 500px;

}