
.match-card-d{
    /* padding:20px 0 5px 20px; */
    padding: 16px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    border-bottom: 1px solid #E0E0E0;
}
.match-card-d:hover{
    /* border-right: 3px solid #025A4E; */
    background-color: white;
}
.match-name-d{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;        
}
.team-info-d{
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 5px;
    color: #0A0A0A;
}
.match-teams-d{
    display:flex;
    align-items: center;
    /* padding-bottom: 10px; */
    padding-top: 10px;
    
}
.vs-d{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #878787;
    margin: 0 6px;
    /* padding-bottom: 10px; */
}
.match-time-d{
    /* font-family: 'Inter', sans-serif; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}
.set-match-bgcolor-d{
    background: rgba(158, 211, 204, 0.21)
}
