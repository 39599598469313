/* General styles for the feedback button and modal */
.feedback-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 900; /* high index to ensure it stays on top */
}

.feedback-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 4px;
    width: 104px;
    height: 36px;
    background: #3287D4;
    border-radius: 100px;
    color: white;
    border: none;
    cursor: pointer;
}

.feedback-modal-overlay, .thank-you-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-color: #8d8d8d;
    font-family: inter;
    line-height: 2;
}

.feedback-form, .thank-you-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 48px rgba(186, 186, 186, 0.43);
    border-radius: 12px;
    width: 341px;
    position: relative;
}

.feedback-form textarea {
    width: 309px;
    height: 205px;
    padding: 16px;
    border: 1px solid #8D8D8D;
    border-radius: 8px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    line-height: 23px;
    color: #7A7A7A;
    box-sizing: border-box;
    /* margin-top: 20px; */
}

.feedback-form button, .thank-you-ok-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;
    width: 309px;
    height: 44px;
    background: #00876C;
    border: none;
    border-radius: 1000px;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.feedback-form button:hover, .thank-you-ok-button:hover {
    background: #006A54;
}

.thank-you-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.thank-you-modal p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    color: #333;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 25px;
}

/* Media Query for Mobile View */
@media (max-width: 600px) {
    .feedback-container {
        bottom: 80px; /* Adjusted position for mobile view */
        right: 20px; /* You can also adjust this if needed */
    }
}

.feedback-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.feedback-header p{
    /* margin: 0 auto; */
    width: 99px;
    /* height: 36px; */
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0A0A0A;
}