.land-header-container{
    text-align: center;
    width:100%;
    position:relative;
}

.land-top-left{
    text-align: center;
    padding:30px 0px 60px 0px;
    position:relative;
   /* background-image: url("../../../public/images/common/rectangle.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-color: aqua; */
}
.land-logo-img {
    position:absolute;
    left:0;
    right:0;
    margin:auto;
    z-index:10;
}
.land-bg-image{
    width:100%;
    height:150px;
    position:absolute;
    top:0;
    z-index:1;
}
.land-bg-image img{
    width:100%;
    height:100%;
}
.land-logo-name-header{
    width: 135px;
    
}
.land-top-right{
    display:none;
}
.land-center-section-one{
    padding:0px 20px;
    margin-top:80px;
}
.land-center-textone{
    font-family: 'Plus Jakarta Display';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0A0A0A;
    margin-left:auto;
    margin-right:auto;
}
.land-center-texttwo{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0A0A0A;
    margin-bottom: 40px;
    margin-top: 15px;
    padding:0px 5px;
}
.land-btn-wrapper{
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    gap:15px;
}
.land-build-btn{
    padding: 12px 15px;
    background: #00876C;
    border-radius: 1000px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    border:none;
    outline:none;
    width:40%;
    cursor:pointer;
}
.land-login-btn, .land-get-started-btn{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #00876C;
    padding: 12px 24px;
    border: 2px solid #00876C;
    border-radius: 1000px;
    cursor:pointer;
}
.land-center-one-right{
    margin-top: 15px;
}
.cricbotz-img{
    width:100%;
}

.modal-overlay{
    width:100vw;
    height:100vh;
    background-color: rgba(128,128,128,0.5);
    position:fixed;
    top: 0;
    left: 0;
    z-index: 110;
}



@media only screen and (min-width:768px){
    .land-top{
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding:0px 20px;
        padding-top:40px;
    }
    .land-top-left{
        padding:0px 0px;
    }
    .land-logo-img{
        width:135px;
        position:static;
    }
    .land-logo-name-header{
        width:100%;
    }
    .land-bg-image{
        display:none;
    }
    .land-top-right{
        display:flex;
        align-items: center;
        gap:20px;
  
    }
    .land-login-btn{
        display:none;
    }
    .land-header-right-text{
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #0A0A0A;
        cursor:pointer;
        text-decoration: none;
    }
    
    .land-center-section-one{
        width:90%;
        margin-left: auto;
        margin-right: auto;
        margin-top:70px;
        margin-bottom:50px;
    }
    .land-build-btn{
        width:40%;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
    }
    .land-center-textone{
        font-size: 68px;
        line-height: 64px;
        letter-spacing: 0.02em;
        text-align: left;
    }
    .land-center-texttwo{
        font-size: 18px;
        line-height: 21px; 
        text-align: left;   
    }
}

@media only screen and (min-width : 1024px) {
    .land-header-container{
        background-image: url(../../../public/images/common/top-bg-img.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
    }
    .land-bg-image{
        display:none;
    }
    
    .land-center-section-one{
        display: flex;
        align-items: center;
        width:100%;
        
    }
    .land-center-one-left{
        width:40%;
    }
    .land-center-one-right{
        width:55%;
        height:558px;
        margin-top: 0px;
    }
    .land-btn-wrapper{
        justify-content: flex-start;
    }
    .land-build-btn{
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
    }
    .cricbotz-img{
        width:100%;
        height:100%;
    }
    .land-center-textone{
        font-weight: 400;
        font-size: 68px;
        line-height: 64px;
        /* or 94% */
        letter-spacing: 0.02em;
    }
}

