.cheat-sheet-container{
    width:100%;
    min-height:100vh;
    background-color: #EDE7DE;
}
.build-team-btn{
    display:block;
    position:fixed;
    left: 50%;
    transform: translate(-50%);
    right:50%;
    bottom: 50px;
    width: 40%;
    background-color: #00876C;
    box-shadow: 0px 0px 48px rgba(2, 133, 115, 0.43);
    border-radius: 1000px;
    padding: 12px 20px;
    border:none;
    color:#FFFFFF;
    cursor: pointer;
    
}