.match-selected-container{
    padding:16px;
    background-color: #F6F3EF;
    border-bottom: 1px solid #E0E0E0;
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap:40px;
    width:100%;
}

.team-info-highlight{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0A0A0A;
}
.vs-highlight{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A;
    margin:0 5px;
}
.match-time-highlight{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7A7A7A
}
.hyphen{
    margin:0 2px;
}
.match-selected-left{
    display:flex;
    gap:10px;
}
.match-selected-right{
    display:flex;
    gap:12px;
}
.match-selected-arrow,.match-selected-note, .match-selected-question{
    width:20px;
    height:20px;
    cursor: pointer;
} 

.chat-question{
    width:30px;
    height:30px;
    cursor: pointer;
} 