.select-player-modal-containerM{
    width:100%;
    height:100vh;
    position:fixed;
    bottom: 0;
    left:0;
    overflow-y: scroll;
    background-color: rgba(128,128,128,0.5);
}

.player-list-each-player{
    display:flex;
    justify-content: space-between;
    padding:10px 0px;
}

.player-list-player-item{
    display:flex;
    align-items: center;
    gap:8px;

}
.player-list-player-item .pl-icon{
    width: 20px;
    height: 20px;
} 
.player-list-player-item span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #0A0A0A;
}