.match-highlight-wrapper-d{
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
}
.team-info-highlight-d{
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0A0A0A;
}
.vs-highlight-d{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #7A7A7A;
    margin:0 10px;
}
.match-time-highlight-d{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7A7A7A;
}
.hyphen-d{
    margin:0 2px;
}
.match-selected-question-d{
    cursor: pointer;
    width: 24px;
    height:24px;
}