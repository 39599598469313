.register-container{
    width:100%;
    /* height:100vh; */
    display: flex;
    flex-direction: column;
    /* gap:20px; */
    background: #FFFFFF;
    padding: 32px 16px;
}
.register-header{
    display:flex;
    justify-content: space-between;
}
.reg-logo-name-header{
    width:100px;
}