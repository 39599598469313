.m-select-players-container{
    padding:24px 16px;
    background-color: #EDE7DE;
    min-height: 100vh;
}
.select-players-toptext{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0A0A0A;
    
}
.players-text-block{
    display:flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    background-color: #D5E5FF;
    border-radius: 4px;
    margin: 16px 0px;
}
.players-text-block span{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
}

.player-list-teams{
    display:flex;
    border: 1px solid #A8A8A8;
    border-radius: 8px;
    padding: 6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0A0A0A;
}

.player-list-team-name{
    padding: 8px 4px;
    width:50%;
    display:flex;
    justify-content: center;
    gap:5px;
}

.player-list-team-name .player-count{
    padding: 0px 7px;
    width: 26px;
    height: 16px;
    background-color: #025A4E;
    border-radius: 8px;
    /* font-family: 'Public Sans', sans-serif; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-team-white{
    background-color: #F6F3EF;
    border-radius: 8px;
}

.player-list-wrapper{
    margin-top:16px;
    background-color: #F6F3EF;
    border-radius: 8px; 
    padding: 24px 16px;
}
.player-list-top-text{
    text-align: center;
    padding: 4px 0px;
    background-color: #A8A8A8;
    border-radius: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.player-list-each-player{
    display:flex;
    justify-content: space-between;
    padding:10px 0px;
}
.player-list-player-item{
    display:flex;
    align-items: center;
    gap:8px;

}
.player-list-player-item .pl-icon{
    width: 20px;
    height: 20px;
} 
.player-list-player-item span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #0A0A0A;
}
.select-players-next-btn{
    text-align: center;
    margin-top: 10px;
}
.select-players-next-btn button{
    width: 20%;
    padding: 10px;
    border-radius: 1000px;
    border:none;
    color:#FFFFFF;
    cursor: pointer; 
   
}
.select-players-next-btn .select-player-next-act{
    background-color: #00876C;
    box-shadow: 0px 0px 48px rgba(2, 133, 115, 0.43);
}
.select-players-next-btn .select-player-next-deact{
    background-color:#A8A8A8;
}

.select-player-error{
    display:flex;
    align-items: center;
    gap:10px;
    padding: 10px 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #0A0A0A;
    background: linear-gradient(0deg, rgba(255, 138, 138, 0.31), rgba(255, 138, 138, 0.31)), #FFFFFF;
    border: 1px solid #E54949;
    border-radius: 8px;
    margin-bottom: 10px;
}

@media (min-width: 1024px) {
    .select-player-error {
        width: 75%; /* Set width to 75% for desktop screens */
    }
}

