.GenCombModal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.GenCombModal-container {
    width: 80%;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
}
/* .GenCombModal-container-hide{
    display:none;
} */
.GenCombModal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    width:20px;
    height:20px;
}

.GenCombModal-header {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #0A0A0A;
    margin-top:20px;
}

.GenCombModal-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #8D8D8D;
}

.GenCombModal-circleContainer {
    display: flex;
    justify-content: space-around;
    gap:10px;
    margin-top: 20px;
}

.GenCombModal-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #8d8d8d;
    color: #8d8d8d;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer; /* Change cursor to pointer on hover */
}

.GenCombModal-circle:hover,
.GenCombModal-circle.selected { /* Styles when hovered or selected */
    border-color: #00876c;
    color: #00876c;
}

.GenCombModal-saveButton {
    width: 100%; /* Adjusted for 25px margin on each side */
    padding: 10px;
    background-color: #00876c;
    color: #FFFFFF;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
}
.GenCombModal-disSavebtn{
    width: 100%; /* Adjusted for 25px margin on each side */
    padding: 10px;
    background-color: #A8A8A8;
    color: #FFFFFF;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;    
}


.GenCombModal-subtext img {
    vertical-align: middle; /* Aligns the icon vertically with the middle of the text line */
    margin-left: 5px; /* Adds a small space between the text and the icon */
}

@media only screen and (min-width : 1024px) {
    .GenCombModal-container {
        width: 500px;
        height: 250px;
    }
    .GenCombModal-header {
        /* font-size: 20px;
        font-weight: 600;
        padding-top: 20px; */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.01em;

        color: #0A0A0A;

    }
    .GenCombModal-subtext {
        margin-top: 10px;
    }
    .GenCombModal-circle {
        width: 30px;
        height: 30px;
    }
    .GenCombModal-saveButton {
        width: calc(100% - 50px); /* Adjusted for 25px margin on each side */
        margin-left: auto;
        margin-right: auto;
    }
    .GenCombModal-disSavebtn{
        width: calc(100% - 50px); /* Adjusted for 25px margin on each side */
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width:1024px){
    .GenCombModal-container {
        width: 500px;
        height: 250px;
    }
}