.approachInput {
    position: relative;
    /* width: 80%; */
    /* height: 100%; */
    background-color: #ede7de;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: 'Inter', sans-serif; /* Set font here if universally applicable */
}

.approachInput h1, .approachInput h2 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #0A0A0A;
}

.approachInput h2 {
    margin-top: 20px;
    padding-bottom: 20px;
}

/* .approach-form {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
    max-height: 20vh;
    overflow-y: auto;
} */
.approach-form {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 15px;
    justify-content: center;
}


/* .guess-card {
    background-color: #fff;
    padding: 20px;
    padding-bottom: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
} */

/* .card {
    display: flex;
    flex-direction: column;     
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: #f6f3ef;
    cursor: pointer;
    font-family: inter;
    line-height: 1.6;
} */

.card {
    display: flex;
    /* flex-direction: column; Aligns child elements vertically */
    
}
/* .top-content {
    display: flex;
    align-items: center; 
} */

.top-content{
    width:100%;
    padding: 16px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background-color: #f6f3ef;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    margin-bottom: 15px;
    height: 15vh;
    overflow-y: auto;
}

.top-content::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
  }
  .top-content::-webkit-scrollbar-thumb {
    background-color: #025A4E;
    border-radius: 2px;
  }

.top-content p{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6;
    color: #7A7A7A;
    text-align: justify;
    margin-left:22px;
    padding-top: 5px;
}

.card p{
    font-family: inter;
    font-size: 14px;
    font-weight: 400;
    color: #868686;
    line-height: 1.6;
}

.top-content input[type="radio"] {
    margin-right: 10px; /* Space between radio button and the text */
    
}

.card input[type="radio"] {
    margin-right: 10px; /* Space between radio button and the content */
    align-self: center; /* Aligns the radio button at the top of the flex container */
}

.card-content {
    display: flex;
    flex-direction: column; /* Stacks h2 and p vertically */
    align-items: start; /* Aligns content to the start of the flex container */
}
.card-content h2, .guess-card h2,  .guess-card-firstinning h2, .guess-card-secondinning h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
    color: #0A0A0A;
}

.card-content h2 {
    margin: 0; /* Removes margin to tightly pack the text */
}
.card-content p {
    line-height: 1.6;
}
/* .card-content p {
    margin-top: 5px;
} */
.guess-card-firstinning p, .guess-card-secondinning p {
    /* margin: 0; */
    font-weight: 400;
    font-size: 14px;
    color: #7A7A7A;
    line-height: 1;
}

.bold-instruction {
    font-weight: bold;
    margin-top: 10px;
}

.guess-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allow wrapping of items */
    gap: 20px;
    justify-content: center;
    margin-top: 10px;
    overflow-y: auto;
    height: 50%;
}
.guess-card-firstinning,.guess-card-secondinning {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    overflow: auto; /* Ensures scrollbar appears when content overflows */
    padding: 20px; /* Adds padding inside the card */
    border-radius: 8px;
    background-color: #f6f3ef;
    flex: 1 1 auto; /* Allows the card to expand and shrink as needed */
    max-height: 40vh; /* Sets a max height to trigger overflow */
    
    
}

.guess-card-firstinning::-webkit-scrollbar,.guess-card-secondinning::-webkit-scrollbar{
    width: 3px;
    cursor: pointer;
  }
  .guess-card-firstinning::-webkit-scrollbar-thumb,.guess-card-secondinning::-webkit-scrollbar-thumb {
    background-color: #025A4E;
    border-radius: 2px;
  }


.input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin-top: 10px; */
    padding-bottom: 10px;
}
.input-group label {
    white-space: nowrap; /* Prevents label from breaking into multiple lines */
    font-weight: 500;
    font-size: 14px;
}

.input-group input, .input-group .toggle-button-group, .input-group select {
    width: calc(100% - 160px);
    max-width: 120px;
    height: 33px;
    border-radius: 7px;
    border: 1px solid #A8A8A8;
    text-align: right;
    padding-right: 5px;
    background-color: #f6f3ef;
}

.toggle-button-group {
    display: flex;
    justify-content: flex-end;    
}

.phase-inputs .phase-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.phase-inputs .phase-row > .MuiFormControl-root {
    flex: 1;
}

.phase-row{
    margin-top: 20px;
}

.approach-input-button-container {
    /* margin-top: 20px; */
    /* padding-top: 20px; */
    width:100%;
    display: flex;
    justify-content: center;
    gap: 20px; 
    /* padding-bottom: 10px; */
    /* background-color: aqua; */
    
    /* position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%); */
}

.back-to-insights-button {
    display: flex; /* Ensures flexbox layout */
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    border-radius: 40px;
    /* background-color: #f6f3ef; */
    background-color: #fff;
    color: black;
    text-transform: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    font-size: 14px;
}


.back-to-insights-button:hover, .back-to-insights-button:focus {
    box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5);
}

.fetch-teams-button {
    border-radius: 40px;
    background-color: #00876c;
    color: white;
    text-transform: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: box-shadow 0.3s, background-color 0.3s;
    /* height: 50px; */
    /* font-size: 16px; */
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.fetch-teams-button:hover {
    background-color: #007256; /* Slightly darker green on hover */
    box-shadow: 0 0 8px 3px rgba(0, 135, 108, 0.5);
    /* box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5); */
}

.fetch-teams-button:focus {
    box-shadow: 0 0 12px 4px #00876c; 
    /* box-shadow: 0 0 8px 3px rgba(41, 41, 41, 0.5); */
}